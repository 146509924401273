import {Component, Input, OnInit} from '@angular/core';
import {ApplicationValidationError} from '../../../models/application-validation-error';
import { ReviewValidationError } from '../../../models/review-validation-error';
import {Question} from '../review/page';

@Component({
  selector: 'sd-app-review-question',
  templateUrl: './review-question.component.html',
  styleUrls: ['./review-question.component.scss']
})
export class ReviewQuestionComponent implements OnInit {
  constructor() {
  }

  @Input() question: Question;
  @Input() panelIndex?: number;
  @Input() applicationValidationErrors: ApplicationValidationError[]; // list of application validation errors from sadabff
  @Input() reviewValidationErrors: ReviewValidationError[];  // list of validation errors from UI

  questionError: string;

  ngOnInit(): void {
    this.questionError = this.findReviewValidationError(this.question);
  }

  getDisplayValues(question: Question) {
    return {
      questionLabel: question.label,
      questionLabelParams: {index: this.panelIndex + 1, ...question.populatedLabelParams},
      questionLabel2: question.label2,
      answer: question.isDollarValue ? '$' + question.value : question.value,
      ariaLabel: this.ariaLabel(question.value),
      skipValueTranslate: question.skipValueTranslate
    }
  }

  getDisplayValuesForSubHeading(question: Question) {
    return {
      questionLabel: question.label,
      ariaLabel: this.ariaLabel(question.label)
    }
  }

  getPanelLabelParams(index, params) {
    return {index: index + 1, ...params}
  }

  getReviewQuestions(panel: any) {
    return panel.questions.filter((question) => {
      return question.getType() !== 'html' && question.getType() !== 'image' && question.isVisible && question.parent.isVisible
    });
  }

  private ariaLabel(value: string): string {
    return value === '' || value === '--' ? 'not.specified' : value;
  }

  findApplicationValidationError(question: Question, panelIndex: number): ApplicationValidationError {
    if (this.applicationValidationErrors && this.applicationValidationErrors.length) {
      return this.applicationValidationErrors.find((error) => panelIndex !== undefined ?
                        question.name === error.field && panelIndex === error.index : question.name === error.field)
    }
    return undefined;
  }

  findReviewValidationError(question: Question): string {
    if (this.reviewValidationErrors && this.reviewValidationErrors.length) {
      let error: ReviewValidationError = this.reviewValidationErrors.find(error => question.name === error.field);
      return error ? error.msgKey : undefined;
    }
    return undefined;
  }

  checkApplicant(params) {

    if(params && params.param0) {
      return true;
    }
    else{
      return false;
    }
  }
}
