import {Injectable} from '@angular/core';
import {Choice} from '../../common/utils/questions/question-choices';
import {RelationshipType} from '../../models/relationship-type';

@Injectable()
export class BeforeYouStartTwoQuestionService {
}

export const RelationshipsToApplicantChoices: Choice[] = [
  {value: RelationshipType.RELATIONSHIP_TRUSTEE, label: 'choices.applyingForSomeoneElseRelationship.trustee'},
  {value: RelationshipType.RELATIONSHIP_ORGANIZATION_TRUSTEE, label: 'choices.applyingForSomeoneElseRelationship.organizationTrustee'},
  {value: RelationshipType.RELATIONSHIP_OTHER, label: 'choices.applyingForSomeoneElseRelationship.other'},
  {value: RelationshipType.APPLICATION_SUPPORT, label: 'choices.applyingForSomeoneElseRelationship.support'},
  {value: RelationshipType.LOCAL_OFFICE_ASSISTANCE, label: 'choices.applyingForSomeoneElseRelationship.localOfficeAssistance'}
];

export const StartTwoPopup = {
  notEligible: {
    title: 'start-two.dialog.notEligible.title',
    body: 'start-two.dialog.notEligible.body',
    bodyLink: {},
    button: 'btn.exit.application',
    link: ''
  },
  redirectODSPFirstNations:{
    title: 'start-two.dialog.redirectODSPFirstNations.title',
    body: 'start-two.dialog.redirectODSPFirstNations.body',
    button: 'start-two.dialog.redirectODSPFirstNations.button',
    link: 'start-two.dialog.redirectODSPFirstNations.link'
  },
  reOpenApplication: {
    title: 'start-two.dialog.reOpenApplication.title',
    body: 'start-two.dialog.reOpenApplication.body',
    bodyLink: {},
    button: 'start-two.dialog.reOpenApplication.button',
    link: 'start-two.dialog.reOpenApplication.link'
  },
  reOpenODSApplication: {
    title: 'start-two.dialog.reOpenODSApplication.title',
    body: 'start-two.dialog.reOpenODSApplication.body',
    bodyLink: {},
    button: 'btn.exit.application',
    link: 'start-two.dialog.reOpenODSApplication.link'
  },
  dateOfRelease: {
    title: 'start-two.dialog.dateOfRelease.title',
    body: 'start-two.dialog.dateOfRelease.body',
    button: 'btn.exit.application',
    link: ''
  },
  livingOnFirstNationsReserveLand: {
    title: 'start-two.dialog.livingOnFirstNationsReserveLand.title',
    body: 'start-two.dialog.livingOnFirstNationsReserveLand.body',
    button: 'start-two.dialog.livingOnFirstNationsReserveLand.button',
    link: 'start-two.dialog.livingOnFirstNationsReserveLand.link'
  },
  emergencyAssistance:{
    title: 'start-two.dialog.emergencyAssistance.title',
    body: 'start-two.dialog.emergencyAssistance.body',
    button: 'start-two.dialog.emergencyAssistance.button'
  }
}
