<sd-page-container [pageId]="pageId" [showResumeBanner]="showResumeBanner" [showPsRedirectError]="showPsRedirectError"
                   [showBackButton]=true>

  <div id="page-alerts-container">
    <sd-info-text header="financial-independence.page.info.header"
                  contentId="financialIndependencePageInfo"
                  content="financial-independence.page.info.content">
    </sd-info-text>
  </div>
  <form [formGroup]="form" sdDisableAutocomplete>
    <div class="form-group required">
      <sd-radio-question [choices]="defaultRevertedRadio" [showError]="showError"
                         [id]="'financial-independence.outOfHighSchoolForFiveYears.question'"
                         [required]="true"
                         controlName="outOfHighSchoolForFiveYears">
      </sd-radio-question>

      <sd-radio-question [choices]="defaultRadio" [showError]="showError"
                         [id]="'financial-independence.haveDiplomaOrDegree.question'"
                         [required]="true"
                         controlName="haveDiplomaOrDegree"
                         [context]="'financial-independence.haveDiplomaOrDegree.context'">
      </sd-radio-question>

      <sd-radio-question [choices]="defaultRadio" [showError]="showError"
                         [id]="'financial-independence.receivedSoleSupportStudentAssistance.question'"
                         [required]="true"
                         controlName="receivedSoleSupportStudentAssistance"
                         [hintText]="'financial-independence.receivedSoleSupportStudentAssistance.hint-text'"
                         [context]="'financial-independence.receivedSoleSupportStudentAssistance.context'">
      </sd-radio-question>
    </div>
    <app-mccss-save-continue-button [pageId]="pageId"
                                    dataE2E="continueBtn"
                                    (handlePrimaryButtonClick)="onSubmit(true)"
                                    (handleSecondaryButtonClick)="onSubmit(false)">
    </app-mccss-save-continue-button>
  </form>
</sd-page-container>
