import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ControlContainer, FormBuilder, ValidatorFn, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subject} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';

import {TranslationKeyFinderPipe} from '@shared/pipe/translation-key-finder.pipe';
import {SadaCustomValidator} from '../../../validator/sada-custom-validator';
import {StringUtil} from '../../../utils/string-util';
import {Choice} from '../../utils/questions/question-choices';
import {phoneNumberValidator} from '../../utils/validators/phone-number-validator';
import {
  asyncFunctionValidator,
  customErrMsgMinAndMaxLengthValidators,
  functionValidator,
  regexValidators
} from '../../utils/validators/custom-validators';
import {QuestionBaseComponent} from '../QuestionBaseComponent';
import {DataType, TextQuestionComponentUtil} from './util/text-question-component.util';

@Component({
  selector: 'sd-text-question',
  templateUrl: './text-question.component.html',
  styleUrls: ['./text-question.component.scss']
})
export class TextQuestionComponent extends QuestionBaseComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input()
  public controlName: string;
  @Input()
  public showError = false;
  @Input()
  public showCustomRequiredErrorMessage: string | undefined;
  @Input()
  public showCustomError: string | undefined;
  @Input()
  public customErrorParam: object | undefined;
  @Input()
  public showCustomErrorParam: string | undefined;
  @Input()
  public validationRegex: string | undefined;
  @Input()
  public validationRegexError: string | undefined;
  @Input()
  public validationFns: {validationFunction: (params: any) => boolean, errorKey: string, errorParam?: any}[] | undefined;
  @Input()
  public asyncValidationFns: [{validationFunction: any, errorKey: string}] | undefined;
  @Input()
  public id: string | undefined;
  @Input()
  public label: string | undefined;
  @Input()
  public required: boolean | false;
  @Input()
  public formatMask: string | undefined;
  @Input()
  public dataType: string | undefined;
  @Input()
  tooltip: string | undefined;
  @Input()
  public minLength: number | undefined;
  @Input()
  public maxLength: number | undefined;
  @Input()
  public characterWidth: number | undefined;
  @Input()
  public showCustomMinMaxLengthErrorMessage: string | undefined;
  @Input()
  public capitalizeFirstLetter: boolean
  @Input()
  public capitalize: boolean
  @Input()
  public extraLabelDescription: string | undefined
  @Input()
  public elementIndex: string;  // To use it for multiple parent panels scenario
  @Input()
  public labelParam: any | {};  // To use for the label text(s) substitution
  @Input()
  public validateOnlyOnBlur: boolean;  // To force the validation taken place only when the client tabs out of the field
  @Input()
  public isDollarValue: boolean; // To include $ sign
  @Input()
  public allowsNumbersOnly: boolean; // Only numbers are allowed to be entered
  @Input()
  public isAutoComplete: boolean;
  @Input()
  public autoCompleteChoices: Choice[];
  @Input()
  public isAlert: boolean;
  @Input()
  public alertMessage: string;
  @Input()
  public truncateMultipleSpaces: boolean;
  @Input()
  public customMinMaxLenErrMsg

  @Output()
  blurEvent = new EventEmitter<any[]>();
  @Output()
  focusEvent = new EventEmitter<any[]>();

  readonly ELEMENT_ID_PREFIX = 'input-';
  customError: string |undefined;
  customRequiredErrorMessage: string | undefined;
  filteredAutoCompleteOptions: Observable<string[]>;
  autoCompleteOptions = [];
  private readonly _destroyed$ = new Subject<void>();

  constructor(public formBuilder: FormBuilder, public translator: TranslateService,
              public controlContainer: ControlContainer, private translationKeyFinderPipe: TranslationKeyFinderPipe) {
    super(controlContainer, formBuilder);

    this.translator.onLangChange.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      if (this.isAutoComplete) {
        this.autoCompleteOptions = [...this.autoCompleteOptions.map(
          opt => this.translator.instant(this.translationKeyFinderPipe.transform(opt)))]

        if (this.formControl.value) {
          const translatedValueOption = this.autoCompleteChoices.find(choice=>choice.value===this.formControl.value);
          const translatedValue = translatedValueOption ? this.translator.instant(translatedValueOption.label) : this.formControl.value;
          this.formControl.setValue(translatedValue)
        } else {
          this.formControl.updateValueAndValidity({onlySelf: false, emitEvent: true})
        }
      }
    });
  }

  ngOnInit(): void {
    // Ideally using label would be good however previously used places used id to pass label key
    this.label = this.label ? this.label : this.id;
    this.setUpForm(this.controlName, (!!this.validateOnlyOnBlur || !!this.asyncValidationFns));
    this.setupAttributeAndValue();
    this.setupValidators();

    if (this.isAutoComplete) {
      if (this.formControl.value) {
        const translatedValueOption = this.autoCompleteChoices.find(choice=>choice.value===this.formControl.value);
        const translatedValue = translatedValueOption ? this.translator.instant(translatedValueOption.label) : this.formControl.value;
        this.formControl.setValue(translatedValue)
      }
      this.filteredAutoCompleteOptions = this.formControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this.filterAutoCompleteOptions(value)));
      this.translateAutoCompleteKeys();
    }
  }

  private filterAutoCompleteOptions(value: string): string[] {
    return this.autoCompleteOptions.filter(option => value === null ? true : option.toLowerCase().includes(value.toLocaleLowerCase()))
  }

  translateAutoCompleteKeys(): void {
    const filteredAutoCompleteChoices = this.autoCompleteChoices.filter(option=>
      option.lang === this.translator.currentLang || option.lang === undefined)
    const keys = filteredAutoCompleteChoices.map(item => item.label);
    this.translator.get([...keys]).pipe(takeUntil(this._destroyed$)).subscribe(m => {
      keys.forEach(k => {
        this.autoCompleteOptions.push(m[k]);
      });
    })
  }

  private setupAttributeAndValue(): void {
    this.allowsNumbersOnly = TextQuestionComponentUtil.shouldAllowsNumbersOnly(this.dataType);
    if (TextQuestionComponentUtil.shouldFormatControlValue(this.dataType)) {
      TextQuestionComponentUtil.formatControlValue(this.dataType, this.formControl);
    }
    if (this.disabled) {
      this.formControl.disable();
    }
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((this.form && changes.required && !changes.required.isFirstChange())
          || (changes.validationFns && !changes.validationFns.isFirstChange())) {
      this.form.clearValidators()
      this.setupValidators()
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    // Unsubscribe to events when component is destroyed.
    this._destroyed$.next(undefined);
    this._destroyed$.complete();
  }

  setupValidators() {
    const validators: ValidatorFn[] = []

    if (this.required) {
      validators.push(Validators.required)
    }

    if (this.validationRegex) {
      validators.push(regexValidators(this.validationRegex, this.validationRegexError))
    }

    if (this.minLength && this.maxLength && !!this.customMinMaxLenErrMsg) {
      validators.push(customErrMsgMinAndMaxLengthValidators(this.customMinMaxLenErrMsg, this.minLength, this.maxLength));
    }else{
      validators.push(Validators.minLength(this.minLength), Validators.maxLength(this.maxLength));
    }

    if (this.validationFns) {
      this.validationFns.forEach(v => {
        validators.push(functionValidator(v.validationFunction, v.errorKey, v.errorParam))
      })
    }
    validators.push(...this.setupValidatorBasedOnDataType(this.dataType));

    if (this.asyncValidationFns) {
      this.asyncValidationFns.forEach(v => {
        this.formControl.setAsyncValidators(asyncFunctionValidator(v.validationFunction, v.errorKey, 0))
      })
    }

    this.formControl.setValidators(validators)
    this.formControl.updateValueAndValidity()
  }

  public getInputClass (characterWidth): string {

    switch(characterWidth) {
      case 20:
        return 'ontario-input--20-char-width'
      case 10:
        return 'ontario-input--10-char-width'
      case 7:
        return 'ontario-input--7-char-width'
      case 5:
        return 'ontario-input--5-char-width'
      case 4:
        return 'ontario-input--4-char-width'
      case 3:
        return 'ontario-input--3-char-width'
      case 2:
        return 'ontario-input--2-char-width'
      default:
        return ''
    }
  }

  private setupValidatorBasedOnDataType(dataType: string): ValidatorFn[] {
    const validators: ValidatorFn[] = []
    switch (dataType) {
      case DataType.CISN:
        const indianStatusNumberValidators = [
          {
            validationFunction: (value: any) => SadaCustomValidator.validateStringHasOnlyNumericChars(value),
            errorKey: 'status-in-canada.error.invalidCertificateOfIndianStatusNumber.length'
          },
          {
            validationFunction: (value: string[]) => value && value[0] && StringUtil.removeSinHealthCardNumberSeparators
              (value[0]).length === 10, errorKey: 'status-in-canada.error.invalidCertificateOfIndianStatusNumber.length'
          }
        ];
        indianStatusNumberValidators.forEach(v => {
          validators.push(functionValidator(v.validationFunction, v.errorKey))
        })
        break;
      case DataType.EMAIL:
        validators.push(Validators.email)
        break;
      case DataType.HN:
        const healthCardNumberValidators = [
          {  // This validation has to be after the duplication check, otherwise, it'd display the wrong message.
            validationFunction: (value) => SadaCustomValidator.validateHealthCardNumberFormat(value),
            errorKey: 'status-in-canada.error.invalidHealthCardNumber'
          },
          {
            validationFunction: (value) => value && value[0] && StringUtil.removeSeparators(value[0]).length === 10,
            errorKey: 'status-in-canada.error.invalidHealthCardNumber.length'
          }
        ];
        healthCardNumberValidators.forEach(v => {
          validators.push(functionValidator(v.validationFunction, v.errorKey))
        })
        break;
      case DataType.PN:
        const phoneNumberValidators = [
          {
            validationFunction: (value) => SadaCustomValidator.validatePhoneNumber([StringUtil.removeSeparators(value[0]), 'CA']),
            errorKey: 'error.invalid.phoneNumber'
          },
          {
            validationFunction: (value) => SadaCustomValidator.validatePhoneNumberFormat(value),
            errorKey: 'error.invalid.phoneNumber'
          },
          {
            validationFunction: (value) => value && value[0] && StringUtil.removeSeparators(value[0]).length === 10,
            errorKey: 'personal-information.invalid.phoneNumber.digits'
          },
        ];
        phoneNumberValidators.forEach(v => {
          validators.push(functionValidator(v.validationFunction, v.errorKey))
        })
        validators.push(phoneNumberValidator('CA'))
        break;
      case DataType.SIN:
        validators.push(functionValidator(SadaCustomValidator.validateSocialInsuranceNumberFormat, 'status-in-canada.error.invalidSIN'));
        break;
    }
    return validators;
  }

  onPaste(clipBoardEvent: any) {
    // This is a workaround to address issue with InputMask where pasted date value is not recognized
    // if (this.dataType === DataType.DATE) {
    //   const clipboardData = clipBoardEvent.clipboardData;
    //   const pastedText = clipboardData.getData('text');
    //   const date = moment(pastedText, 'YYYY / MM / DD');
    //   if (date.isValid()) {
    //     this.formControl.setValue(date.format('YYYY / MM / DD'));
    //   }
    // } else
    if (TextQuestionComponentUtil.shouldAllowsNumbersOnly(this.dataType)) {  // To allow the pasted value can be captured.
      const clipboardData = clipBoardEvent.clipboardData;
      const pastedText = clipboardData.getData('text');
      this.formControl.setValue(pastedText);
      TextQuestionComponentUtil.unformatControlValue(this.dataType, this.formControl)
    }
  }

  onBlur() {
    if (!!this.formControl.value) {
      let value = this.formControl.value.trim();

      if (this.capitalizeFirstLetter) {
        const newValue = value.charAt(0).toUpperCase() + value.slice(1);
        if (newValue !== value) {
          value = newValue
        }
      } else if(this.capitalize) {
        value = value.toUpperCase()
      }

      if(this.truncateMultipleSpaces){
        value = value.replace(/\s\s+/g, ' ')
      }

      this.formControl.setValue(value);
      TextQuestionComponentUtil.formatControlValue(this.dataType, this.formControl);
      this.blurEvent.emit(value);
    } else {
      this.blurEvent.emit();
    }
  }

  onFocus() {
    if (!!this.formControl.value) {
      const value = this.formControl.value.trim();
      TextQuestionComponentUtil.unformatControlValue(this.dataType, this.formControl);
      this.focusEvent.emit(value);
    } else {
      this.focusEvent.emit();
    }
  }

  get fieldName(): string {
    return this.getFieldName(this.id);
  }

  getElementId(prefix: string): string {
    if (this.elementIndex) {
      return prefix.concat(this.id).concat('-').concat(this.elementIndex);
    } else {
      return prefix.concat(this.id);
    }
  }

  get shouldShowErrorStyle(): boolean {
    return (this.showError && this.formControl.hasError('required'))
      || (!this.formControl.hasError('required') && this.formControl.errors?.customErrorKey && (this.formControl.touched || this.showError))
      || ((this.formControl.hasError('minlength') || this.formControl.hasError('maxlength'))
        && (this.formControl.touched || this.showError))
      || (this.formControl.hasError('email') && (this.formControl.touched || this.showError))
      || (this.formControl.hasError('validationRegex') && (this.formControl.touched || this.showError))
      || (this.showCustomError && (this.formControl.touched || this.showError))
      || (!this.formControl.hasError('required') && this.formControl.errors?.customMinMaxErrorKey
        && (this.formControl.touched || this.showError))
  }
}
