import {Injectable} from '@angular/core';
import {Question} from 'src/app/common/utils/questions/question';
import {
  onChangeOfImmFinNeedForFamilyApp,
  requiredIfOtherCheckBoxesAreFalse,
  requiredIfOtherCheckBoxIsFalse, validateODSProgramForFamilyApp, validateODSProgramForSingleApplicant,
  validateReceivedSocialAssistanceApplicationDate
} from 'src/app/common/utils/validators/custom-validators';
import {SadaCustomValidator} from 'src/app/validator/sada-custom-validator';
import {ApplicationAnswers} from '../../models/data.model';
import {UrlInfo} from '../../models/url-map';
import {ProgramType} from '../../models/program-type';

@Injectable()
export class FamilyInformationQuestionService {

  getMultipleAdditionalInformationQuestions(applicationAnswers: ApplicationAnswers): Question<string>[] {
    return [
      {
        controlType: 'radio',
        key: 'familyReceivedSocialAssistanceInPast',
        label: 'additional-information.multipleApplicants.receivedSocialAssistance',
        hintText: {text: 'additional-information.multipleApplicants.receivedSocialAssistance-hintText', expandableContent: 'additional-information.multipleApplicants.receivedSocialAssistance-hintContent'},
        required: true,
        needMarginTop: false,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'familyReceivedSocialAssistanceInPast',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        displayInNewPanel: true,
        key: 'receivedSocialAssistanceInPastPanel',
        needMarginTop: false,
        panels: [{
          name: 'receivedSocialAssistanceInPastPanel',
          questions: this.getApplicantCheckBox('receivedSocialAssistanceInPastCheckBox'),
          showAddButton: false
        }],
        visibleOnConditions: [
          {
            questionKey: 'familyReceivedSocialAssistanceInPast',
            questionControlType: 'radio',
            value: ['yes']
          },
        ],
      disableOthersOnCondition: [
      {
        questionKey: 'receivedSocialAssistanceInPastCheckBox',
        questionControlType: 'radio',
        index: 0,
        value: ['no'],
      },
    ]
      },
      {
        controlType: 'radio',
        key: 'familyMembersResidingInInstitution',
        label: 'additional-information.multipleApplicants.currentlyResidingInAnInstitution',
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'familyMembersResidingInInstitution',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        displayInNewPanel: true,
        key: 'livingInAnInstitutionPanel',
        needMarginTop: false,
        panels: [{
          name: 'livingInAnInstitutionPanel',
          questions: this.getApplicantCheckBox('familyMembersResidingInInstitutionCheckbox'),
          showAddButton: false
        }],
        visibleOnConditions: [
          {
            questionKey: 'familyMembersResidingInInstitution',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'familyMembersCurrentlyIncarcerated',
        label: 'additional-information.multipleApplicants.currentlyIncarcerated',
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'familyMembersCurrentlyIncarcerated',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        displayInNewPanel: true,
        key: 'currentlyIncarceratedPanel',
        needMarginTop: false,
        panels: [{
          name: 'currentlyIncarceratedPanel',
          questions: this.getApplicantCheckBox('familyMembersCurrentlyIncarceratedCheckbox'),
          showAddButton: false
        }],
        visibleOnConditions: [
          {
            questionKey: 'familyMembersCurrentlyIncarcerated',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'additionalFamilyAccommodationServices',
        label: 'additional-information.multipleApplicants.familyAccommodationServices',
        hintText: {text: 'additional-information.accommodationServices-hintText'},
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'additionalFamilyAccommodationServices',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        displayInNewPanel: true,
        key: 'accommodationServicesPanel',
        needMarginTop: false,
        panels: [{
          name: 'accommodationServicesPanel',
          questions: this.getApplicantCheckBox('familyAccommodationServicesCheckbox'),
          showAddButton: false
        }],
        visibleOnConditions: [
          {
            questionKey: 'additionalFamilyAccommodationServices',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'specialDietOrMedicalConditionInFamily',
        label: 'additional-information.multipleApplicants.additionalSpecialDietOrMedicalCondition',
        hintText: {text: 'additional-information.multipleApplicants.additionalSpecialDietOrMedicalCondition-hintText', expandableContent: 'additional-information.multipleApplicants.additionalSpecialDietOrMedicalCondition-hintContent'},
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'specialDietOrMedicalConditionInFamily',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        displayInNewPanel: true,
        key: 'specialDietOrMedicalConditionPanel',
        needMarginTop: false,
        panels: [{
          name: 'specialDietOrMedicalConditionPanel',
          questions: this.getApplicantCheckBox('specialDietOrMedicalConditionInFamilyCheckbox'),
          showAddButton: false
        }],
        visibleOnConditions: [
          {
            questionKey: 'specialDietOrMedicalConditionInFamily',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'personWithDisabilityInFamily',
        label: 'additional-information.multipleApplicants.hasDisability',
        removeLabelMargin: true,
        hintText: {boldHintLabel: 'additional-information.hasDisability-hintLabel', removeHintLabelMargin: true, text: 'additional-information.hasDisability-hintContent'},
        required: true,
        needMarginTop: true,
        innerHtmlError: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'validatorFn',
            validatorFn:(value)=> SadaCustomValidator.validateDisabilityBasedOnFirstNation(value,
              applicationAnswers.jsonData.livingOnFirstNationsReserveLand),
            errorKey: 'additional-information.livingOnFirstNationsReserveLand.error',
            errorKeyParam:{link: [UrlInfo.officeLocation, true]}
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'personWithDisabilityInFamily',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        displayInNewPanel: true,
        key: 'disabilityPanel',
        needMarginTop: false,
        panels: [{
          name: 'disabilityPanel',
          questions: this.getApplicantCheckBox('personWithDisabilityInFamilyCheckbox'),
          showAddButton: false
        }],
        visibleOnConditions: [
          {
            questionKey: 'personWithDisabilityInFamily',
            questionControlType: 'radio',
            value: ['yes']
          },
        ],
        disableOthersOnCondition: [
          {
            questionKey: 'personWithDisabilityInFamilyCheckbox',
            questionControlType: 'radio',
            index: 0,
            value: ['no'],
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'receivingACSDFamily',
        label: 'additional-information.multipleApplicants.receivingACSD',
        required: true,
        needMarginTop: true,
        innerHtmlError: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ],
        visibleOnConditions: [
          {
            customFunction: enableReceivingACSDFamilyFn(applicationAnswers)
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'receivingACSDFamily',
            questionControlType: 'radio',
            value: ['yes']
          },
          {
            customFunction: enableReceivingACSDFamilyFn(applicationAnswers)
          }
        ]
      },
      {
        controlType: 'panel',
        displayInNewPanel: true,
        key: 'receivingACSDPanel',
        needMarginTop: false,
        panels: [{
          name: 'receivingACSDPanel',
          questions: this.getApplicantCheckBox('receivingACSDFamilyCheckbox'),
          showAddButton: false
        }],
        visibleOnConditions: [
          {
            questionKey: 'receivingACSDFamily',
            questionControlType: 'radio',
            value: ['yes']
          },
          {
            customFunction: enableReceivingACSDFamilyFn(applicationAnswers)
          }
        ]
      },
      {
        controlType: 'radio',
        key: 'moneyForImmediateNeedInFamily',
        label: 'additional-information.multipleApplicants.moneyForImmediateNeed',
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ],
        onChangeFn: {onChangeFunction: onChangeOfImmFinNeedForFamilyApp()}
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'moneyForImmediateNeedInFamily',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        displayInNewPanel: true,
        key: 'moneyForImmediateNeedPanel',
        needMarginTop: false,
        panels: [{
          name: 'moneyForImmediateNeedPanel',
          questions: this.getApplicantCheckBox('moneyForImmediateNeedInFamilyCheckbox'),
          showAddButton: false
        }],
        visibleOnConditions: [
          {
            questionKey: 'moneyForImmediateNeedInFamily',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'pregnantOrBreastFeedingInFamily',
        label: 'additional-information.multipleApplicants.nutritionalNeeds',
        hintText: {text: 'additional-information.multipleApplicants.nutritionHintText', expandableContent: 'additional-information.multipleApplicants.nutritionHintContent'},
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'pregnantOrBreastFeedingInFamily',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        displayInNewPanel: true,
        key: 'nutritionalNeedsPanel',
        needMarginTop: false,
        panels: [{
          name: 'nutritionalNeedsPanel',
          questions: this.getApplicantCheckBox('pregnantOrBreastFeedingInFamilyCheckbox'),
          showAddButton: false
        }],
        visibleOnConditions: [
          {
            questionKey: 'pregnantOrBreastFeedingInFamily',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'fullTimeStudentInFamily',
        label: 'additional-information.multipleApplicants.fullTimeStudent',
        hintText: {text: 'additional-information.multipleApplicants.familyMemberStudents-hintText'},
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'fullTimeStudentInFamily',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        displayInNewPanel: true,
        key: 'fullTimeStudentInFamilyPanel',
        needMarginTop: false,
        panels: [{
          name: 'fullTimeStudentInFamilyPanel',
          questions: this.getApplicantCheckBox('fullTimeStudentInFamilyCheckbox'),
          showAddButton: false
        }],
        visibleOnConditions: [
          {
            questionKey: 'fullTimeStudentInFamily',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'caringForChild',
        label: 'additional-information.caringForChild',
        context: 'additional-information.caringForChild.context',
        required: false,
        needMarginTop: true,
        hintText: {text: 'additional-information.multipleApplicants.caringForOthersChildren-hintText', expandableContent: 'additional-information.multipleApplicants.caringForOthersChildren-hintContent'},
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }]
      }
    ];
  }

  getApplicantCheckBox(key: string): Question<string>[] {
    return [
      {
        controlType: 'checkbox',
        key,
        required: true,
        needMarginTop: false,
        displayLabelNormal: 'yes',
        label: 'additional-information.select.all',
        options: [{
          value: false,
          label: 'financial-assets.applicant',
        }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.select.one.person'
          }
        ],
        onChangeFn: {onChangeFunction: onChangeOfImmFinNeedForFamilyApp(true)},
        disableOthersOnCondition: []
      }
    ];
  }

  getSocialAssistanceRecipientDetailQuestions(label: string, labelParams: string[], i: number, datesOfBirth: string[],
                                              applicantType: string) {
    const dateOfBirth = datesOfBirth && datesOfBirth[i] ? datesOfBirth[i] : undefined;
    const trimmedDateOfBirth = dateOfBirth?.slice(0, dateOfBirth.lastIndexOf('/'));  // Only interested in YYYY/MM.

    const saQuestions: Question<string>[] = [
      {
        controlType: 'radio',
        key: 'receivedSocialAssistanceProgram',
        label: 'additional-information.singleApplicant.socialAssistancePanel.receivedSocialAssistanceProgram',
        required: true,
        innerHtmlError: true,
        hintText: {
          text: 'additional-information.singleApplicant.socialAssistancePanel.receivedSocialAssistanceProgram.hintText',
          expandableContent: 'additional-information.singleApplicant.socialAssistancePanel.receivedSocialAssistanceProgram.hintContent'},
        options: [{
          value: ProgramType.ODS,
          label: 'choices.radioDefault.odsp'
        },
          {
            value: ProgramType.ONW,
            label: 'choices.radioDefault.ow'
          },
          {
            value: ProgramType.ACSD,
            label: 'choices.radioDefault.acsd'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'crossField',
            validatorFn: validateODSProgramForFamilyApp(applicantType),
            errorKey: 'error.reopenPastODSApplication',
            errorKeyParam: {link: [UrlInfo.officeLocation, true]}
          }
        ]
      },
      {
        controlType: 'odsdate',
        key: 'applicationDate',
        label,
        labelParams,
        skipContainerLabel: true,
        needMarginTop: true,
        required: true,
        showDay: false,
        validators: [
          {
            type: 'validatorFn',
            params: [trimmedDateOfBirth],
            validatorFn: SadaCustomValidator.validateDateWithMinAndFuture,
            errorKey: 'additional-information.error.invalid.additionalApplicationDate'
          },
          {
            type: 'crossField',
            validatorFn: validateReceivedSocialAssistanceApplicationDate('applicationDate',
              'receivedSocialAssistanceProgram', applicantType),
            errorKey: null
          }
        ]
      },
      {
        controlType: 'textbox',
        key: 'memberID',
        label: 'additional-information.memberID',
        required: false,
        needMarginTop: true,
        characterWidth: 20,
        hintText: {text: 'additional-information.memberID.hintText', expandableContent: 'additional-information.memberID.hintContent'},
        validators: [
          {
            type: 'validatorFn',
            validatorFn: SadaCustomValidator.validateMemberID,
            errorKey: 'additional-information.error.invalid.memberId'
          }
        ]
      }
    ]
    return saQuestions;
  }

  getLivingInAnInstitutionDetailQuestions(institutionLocationLabel: string, labelParams: string[]) {
    const ipQuestions: Question<string>[] = [
      {
        controlType: 'dropdown',
        key: 'institutionLocation',
        label: institutionLocationLabel,
        labelParams,
        required: true,
        needMarginTop: false,
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ],
        options: [
          {
            value: 'INSTITUTION_CAMH',
            label: 'additional-information.livingInAnInstitutionPanel.camh'
          },
          {
            value: 'INSTITUTION_HOMEWOOD_HEALTH',
            label: 'additional-information.livingInAnInstitutionPanel.homewoodHealthCentre'
          },
          {
            value: 'INSTITUTION_HOSPITAL',
            label: 'additional-information.livingInAnInstitutionPanel.hospitalOrMHCentre'
          },
          {
            value: 'INSTITUTION_LONG_TERM_CARE',
            label: 'additional-information.livingInAnInstitutionPanel.longTermCare'
          },
          {
            value: 'INSTITUTION_SUBSTANCE_ABUSE_PROGRAM',
            label: 'additional-information.livingInAnInstitutionPanel.substanceAbuse'
          },
          {
            value: 'INSTITUTION_WOMENS_SHELTER',
            label: 'additional-information.livingInAnInstitutionPanel.womensShelter'
          },
          {
            value: 'INSTITUTION_SPECIAL_CARE',
            label: 'additional-information.livingInAnInstitutionPanel.specialCare'
          },
          {
            value: 'INSTITUTION_GROUP_RESIDENCE',
            label: 'additional-information.livingInAnInstitutionPanel.groupResidence'
          },
          {
            value: 'INSTITUTION_SUPPORTED_RESIDENCE',
            label: 'additional-information.livingInAnInstitutionPanel.supportedResidence'
          },
          {
            value: 'INSTITUTION_SCHOOL_HEARING_IMPAIRED',
            label: 'additional-information.livingInAnInstitutionPanel.hearingImpaired'
          },
          {
            value: 'INSTITUTION_SCHOOL_VISION_IMPAIRED',
            label: 'additional-information.livingInAnInstitutionPanel.visionImpaired'
          },
          {
            value: 'INSTITUTION_SCHOOL_FAMILY_SERVICES',
            label: 'additional-information.livingInAnInstitutionPanel.familyServices'
          }
        ]
      },
      {
        controlType: 'textbox',
        key: 'institutionName',
        label: 'additional-information.livingInAnInstitutionPanel.additionalInstitutionName',
        capitalizeFirstLetter: true,
        required: true,
        needMarginTop: true,
        characterWidth: 20,
        visibleOnConditions: [
          {
            questionKey: 'institutionLocation',
            questionControlType: 'dropdown',
            value: ['', 'INSTITUTION_HOSPITAL', 'INSTITUTION_LONG_TERM_CARE', 'INSTITUTION_SUBSTANCE_ABUSE_PROGRAM',
              'INSTITUTION_WOMENS_SHELTER', 'INSTITUTION_SPECIAL_CARE', 'INSTITUTION_GROUP_RESIDENCE', 'INSTITUTION_SUPPORTED_RESIDENCE',
              'INSTITUTION_SCHOOL_HEARING_IMPAIRED', 'INSTITUTION_SCHOOL_VISION_IMPAIRED', 'INSTITUTION_SCHOOL_FAMILY_SERVICES']
          }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'validatorFn',
            validatorFn: SadaCustomValidator.validateInstitutionName,
            errorKey: 'additional-information.error.invalid.institutionName'
          },
          {
            type: 'maxLength',
            maxLengthValue: 65,
            errorKey: 'additional-information.error.invalid.institutionName.length'
          }
        ]
      }
    ];

    return ipQuestions;
  }

  getCurrentlyIncarceratedDetailQuestions(isPrimaryApplicant: boolean, expectedReleaseLabel: string,
                                          stayPartTimeOrFullTimeLabel: string, stayPartTimeOrFullTimeContext: string,
                                          labelParams: string[]) {
    const incarceratedQuestions: Question<string>[] = [
      {
        controlType: 'odsdate',
        key: 'expectedReleaseDate',
        label: expectedReleaseLabel,
        labelParams,
        dateSmallRightMargin: true,
        skipContainerLabel: true,
        showDay: true,
        required: true,
        validatorFns: [
          {
            validationFunction: (v: []) => {
              return SadaCustomValidator.isValidDate(v) && !SadaCustomValidator.isInPastExcludeToday(v);
            },
            errorKey: 'error.invalid.date'
          },
          {
            validationFunction: (v: []) => {
              // Validate that future release date must be within 10 days only for primary applicant
              return isPrimaryApplicant ? (SadaCustomValidator.isInPastExcludeToday(v)
                                           || SadaCustomValidator.isDateWithinDaysIncludeToday([...v, '10']))
                                        : true;
            },
            errorKey: 'additional-information.error.invalid.dateOfRelease.pastTenDays'
          }
        ]
      },
      {
        controlType: 'radio',
        key: 'stayPartTimeOrFullTime',
        label: stayPartTimeOrFullTimeLabel,
        context: stayPartTimeOrFullTimeContext,
        labelParams,
        required: true,
        needMarginTop: true,
        options: [{
          value: 'full-time',
          label: 'choices.radioDefault.fullTime'
        },
          {
            value: 'part-time',
            label: 'choices.radioDefault.partTime'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      }
    ];
    return incarceratedQuestions;
  }

  getAdditionalAccommodationServicesDetailQuestions(): Question<any>[] {
    return [
      {
        controlType: 'checkbox',
        key: 'visualCheckbox',
        isRequiredRelatedCrossFieldValidator: true,
        options: [{
          value: false,
          label: 'additional-information.multipleApplicants.visualSupport',
        }],
        validators: [
          {
            type: 'crossField',
            validatorFn: requiredIfOtherCheckBoxesAreFalse('visualCheckbox', ['hearingCheckbox','verbalCheckbox','communicationCheckbox','mobilityCheckbox','visualCheckbox']),
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'textarea',
        key: 'visualDescription',
        label: 'additional-information.pleaseDescribe',
        displayCharsRemaining: 250,
        needMarginBottom: true,
        needMarginLeft: true,
        required: false,
        visibleOnConditions: [
          {
            questionKey: 'visualCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          }],
        validators: [
          {
            type: 'maxLength',
            maxLengthValue: 250,
            errorKey: 'additional-information.error.desc.minMaxLength'
          },
          {
            type: 'minLength',
            minLengthValue: 1,
            errorKey: 'additional-information.error.desc.minMaxLength'
          }
        ]
      },
      {
        controlType: 'checkbox',
        key: 'hearingCheckbox',
        isRequiredRelatedCrossFieldValidator: true,
        options: [{
          value: false,
          label: 'additional-information.multipleApplicants.hearingSupport',
        }],
        validators: [
          {
            type: 'crossField',
            validatorFn: requiredIfOtherCheckBoxesAreFalse('hearingCheckbox', ['visualCheckbox','verbalCheckbox','communicationCheckbox','mobilityCheckbox','visualCheckbox']),
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'textarea',
        key: 'hearingDescription',
        label: 'additional-information.pleaseDescribe',
        displayCharsRemaining: 250,
        needMarginBottom: true,
        needMarginLeft: true,
        required: false,
        visibleOnConditions: [
          {
            questionKey: 'hearingCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          }],
        validators: [
          {
            type: 'maxLength',
            maxLengthValue: 250,
            errorKey: 'additional-information.error.desc.minMaxLength'
          },
          {
            type: 'minLength',
            minLengthValue: 1,
            errorKey: 'additional-information.error.desc.minMaxLength'
          }
        ]
      },
      {
        controlType: 'checkbox',
        key: 'verbalCheckbox',
        isRequiredRelatedCrossFieldValidator: true,
        options: [{
          value: false,
          label: 'additional-information.multipleApplicants.verbalSupport',
        }],
        validators: [
          {
            type: 'crossField',
            validatorFn: requiredIfOtherCheckBoxesAreFalse('verbalCheckbox', ['hearingCheckbox','visualCheckbox','communicationCheckbox','mobilityCheckbox','visualCheckbox']),
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'textarea',
        key: 'verbalDescription',
        label: 'additional-information.pleaseDescribe',
        displayCharsRemaining: 250,
        needMarginBottom: true,
        needMarginLeft: true,
        required: false,
        visibleOnConditions: [
          {
            questionKey: 'verbalCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          }],
        validators: [
          {
            type: 'maxLength',
            maxLengthValue: 250,
            errorKey: 'additional-information.error.desc.minMaxLength'
          },
          {
            type: 'minLength',
            minLengthValue: 1,
            errorKey: 'additional-information.error.desc.minMaxLength'
          }
        ]
      },
      {
        controlType: 'checkbox',
        key: 'communicationCheckbox',
        isRequiredRelatedCrossFieldValidator: true,
        options: [{
          value: false,
          label: 'additional-information.multipleApplicants.communicationSupport',
        }],
        validators: [
          {
            type: 'crossField',
            validatorFn: requiredIfOtherCheckBoxesAreFalse('communicationCheckbox', ['hearingCheckbox','verbalCheckbox','visualCheckbox','mobilityCheckbox','visualCheckbox']),
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'textarea',
        key: 'communicationDescription',
        label: 'additional-information.pleaseDescribe',
        hintText: {text: 'additional-information.multipleApplicants.communicationSupport-hintText'},
        displayCharsRemaining: 250,
        needMarginBottom: true,
        needMarginLeft: true,
        required: false,
        visibleOnConditions: [
          {
            questionKey: 'communicationCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          }],
        validators: [
          {
            type: 'maxLength',
            maxLengthValue: 250,
            errorKey: 'additional-information.error.desc.minMaxLength'
          },
          {
            type: 'minLength',
            minLengthValue: 1,
            errorKey: 'additional-information.error.desc.minMaxLength'
          }
        ]
      },
      {
        controlType: 'checkbox',
        key: 'mobilityCheckbox',
        isRequiredRelatedCrossFieldValidator: true,
        options: [{
          value: false,
          label: 'additional-information.multipleApplicants.mobilitySupport',
        }],
        validators: [
          {
            type: 'crossField',
            validatorFn: requiredIfOtherCheckBoxesAreFalse('mobilityCheckbox', ['hearingCheckbox','verbalCheckbox','communicationCheckbox','visualCheckbox','visualCheckbox']),
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'textarea',
        key: 'mobilityDescription',
        label: 'additional-information.pleaseDescribe',
        displayCharsRemaining: 250,
        needMarginLeft: true,
        required: false,
        visibleOnConditions: [
          {
            questionKey: 'mobilityCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          }],
        validators: [
          {
            type: 'maxLength',
            maxLengthValue: 250,
            errorKey: 'additional-information.error.desc.minMaxLength'
          },
          {
            type: 'minLength',
            minLengthValue: 1,
            errorKey: 'additional-information.error.desc.minMaxLength'
          }
        ]
      }
    ];
  }

  // tslint:disable-next-line:max-line-length
  getAdditionalNutritionalNeedsDetailQuestions(pregnantDueDateLabel: string,describesYourSituation:string,lactoseIntolerantDietLabel: string, lactoseIntolerantDietContext:string, labelParams: string[]): Question<any>[] {
    return [
      {
        controlType: 'checkbox',
        key: 'pregnantCheckbox',
        label: describesYourSituation,
        labelParams,
        hintText: {text: 'additional-information.select.all'},
        options: [{
          value: false,
          label: 'additional-information.pregnant',
        }],
        validators: [
          {
            type: 'crossField',
            validatorFn: requiredIfOtherCheckBoxIsFalse('pregnantCheckbox', 'breastFeedingCheckbox'),
            errorKey: 'error.required'
          }
        ],
        disableOthersOnCondition: [
          {
            questionKey: 'dueDate',
            questionControlType: 'textbox',
            index: 0,
            value: [false],
          },
        ]
      },
      {
        controlType: 'checkbox',
        key: 'breastFeedingCheckbox',
        // needMarginTop: true,
        options: [{
          value: false,
          label: 'additional-information.breastFeeding',
        }]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
      },
      {
        controlType: 'odsdate',
        key: 'dueDate',
        label: pregnantDueDateLabel,
        labelParams,
        skipContainerLabel: true,
        showDay: true,
        required: true,
        dateSmallRightMargin: true,
        validatorFns: [
          {
            validationFunction: (value: []) => {
              return SadaCustomValidator.isValidPregnancyDueDate(value);
            },
            errorKey: 'additional-information.error.invalid.pregnancyDueDate'
          }
        ],
        visibleOnConditions: [
          {
            questionKey: 'pregnantCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'lactoseIntolerantDiet',
        label: lactoseIntolerantDietLabel,
        context: lactoseIntolerantDietContext,
        labelParams,
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        visibleOnAnyConditions: [
          {
            questionKey: 'pregnantCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          },
          {
            questionKey: 'breastFeedingCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ],
      }
    ];
  }

  getDSOFamilyQuestion(developmentServicesOntarioLabel: string, labelParams: string[]): Question<string>[] {
    return [
      {
        controlType: 'radio',
        key: 'developmentServicesOntario',
        label: developmentServicesOntarioLabel,
        labelParams,
        required: true,
        needMarginTop: true,
        options: [
          {
            value: 'approved',
            label: 'choices.radioDefault.approved'
          },
          {
            value: 'applied',
            label: 'choices.radioDefault.applied'
          },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      }
    ];
  }

  getFullTimeStudentDetailQuestions(label: string, labelParams: string[]): Question<string>[] {
    return [{
      controlType: 'dropdown',
      key: 'schoolType',
      label,
      labelParams,
      required: true,
      needMarginTop: false,
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        }
      ],
      options: [
        {
          value: 'secondarySchool',
          label: 'additional-information.secondarySchool'
        },
        {
          value: 'postSecondarySchool',
          label: 'additional-information.postSecondarySchool'
        }
      ]
    }]
  }
}
/**
 * enable receiving ACSD radio question for the following condition:
 * DOB is lesser than 20 for the single or a family application.
 */
export const enableReceivingACSDFamilyFn = (appData) => {
  const { dateOfBirth, spouseDateOfBirth, childList } = appData.jsonData;
  return (
    SadaCustomValidator.validateAgeBelow20(dateOfBirth) ||
    SadaCustomValidator.validateAgeBelow20(spouseDateOfBirth) ||
    (childList && childList.some((child) => SadaCustomValidator.validateAgeBelow20(child.childDateOfBirth))) ||
    false
  );
};
