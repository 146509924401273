import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {PageBaseComponent} from '../PageBaseComponent';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../services/intake.service';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ExternalRouter} from '../../external.router';
import {ConfigService} from '../../services/config.service';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {FormBuilder} from '@angular/forms';
import {PageInfo} from '../../models/page-map';
import {DefaultRadio, DefaultRevertedRadio} from '../../common/utils/questions/question-choices';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';

@Component({
  selector: 'sd-financial-independence',
  templateUrl: './financial-independence.component.html',
  styleUrls: ['./financial-independence.component.scss']
})
export class FinancialIndependenceComponent extends PageBaseComponent implements OnInit, OnDestroy {

  defaultRadio = DefaultRadio;
  defaultRevertedRadio = DefaultRevertedRadio
  showError = false;

  constructor(private router: Router,
              public route: ActivatedRoute,
              public intake: IntakeService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService,
              public formBuilder: FormBuilder) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.financialIndependence;
    this.form = this.formBuilder.group({
      outOfHighSchoolForFiveYears: [],
      haveDiplomaOrDegree: [],
      receivedSoleSupportStudentAssistance: []
    });
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  postInitializeForm() {
    this.preSelectedForApplicantOver26(this.applicationAnswers.jsonData)
  }

  preSaveApplication() {
  }

  onSubmit(toContinue: boolean): void {
    if (this.form.valid) {
      this.saveAndContinue(toContinue);
    } else {
      this.showError = true
      this.scrollToInvalidFormControl(toContinue)
    }
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  private saveAndContinue(toContinue: boolean) {
    this.saveForm(toContinue)?.subscribe(x => {
      if (toContinue) {
        this.router.navigate(['/', 'intake', PageInfo.earnedIncome]);
      } else {
        this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
      }
    });
  }

  private preSelectedForApplicantOver26(applicationData: any){
    if(!applicationData.outOfHighSchoolForFiveYears){
      if(SadaCustomValidator.validateOverAge(applicationData.dateOfBirth, 26)){
        this.form.get('outOfHighSchoolForFiveYears').setValue('yes');
      }
    }
  }
}
