import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {InitializeService} from './initialize.service';
import {InitializeResponse} from '../models/initialize-response';
import {ConfigService} from './config.service';
import {AuthService} from './auth.service';
import { AddressConfigService } from './address-config.service';
import { MccssAddressInjectedConfig } from '@mccss/pclookup-common-ui';
import { ClientConfig } from '../models/client-config';

@Injectable({
  providedIn: 'root'
})
export class InitializeServiceImpl implements InitializeService {
  initId: string| undefined;

  constructor(private readonly http: HttpClient, 
    private readonly configService: ConfigService, 
    private readonly authService: AuthService,
    private readonly addressConfigService: AddressConfigService
  ) {
  }

  initialize(): Observable<InitializeResponse> {
    const emptyBlob = new Blob([], { type: 'application/json' });
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<InitializeResponse>(`/v1/initialize`, emptyBlob,{withCredentials: true, headers})
      .pipe(
        tap(response => {
          this.addressConfigService.setAddressConfig(this.getAddressdConfig(response.clientConfig));
        }),
        map(response => {
          this.configService.setClientConfig(response.clientConfig);
          this.authService.setAuthorizedToSave(response.authorizedToSave);
          this.authService.setActiveSessionExists(response.activeSessionExists);
          this.authService.setUserName(response.userName)
          this.authService.setAccessToken(response.accessToken);
          this.initId = response.initId;
          return response;
        })
      );
  }

  getAddressdConfig(clientConfig: ClientConfig | undefined): MccssAddressInjectedConfig {
    // Get URLs configurations
    let urlConfig = this.getClientConfigUrls(clientConfig);

    // URLs of address APIs
    let apiConfig = {
      intuitiveSearchApiUrl: '/v1/address-search',           // Intuitive address search API
      addressChoicesApiUrl: '/v1/address-choices-rest',      // Address choices search API
      postalCodeValidationUrl: '/v1/validation/postal-code'  // Postal code validation API
    }

    return { ...apiConfig, ...urlConfig } as MccssAddressInjectedConfig;
  }

  private getClientConfigUrls(clientConfig: ClientConfig | undefined): MccssAddressInjectedConfig {
    const injectedConfig = {} as MccssAddressInjectedConfig;
    injectedConfig.urls = new Map();
  
    if (clientConfig?.urls) {
      // Iterate over each language in clientConfig.urls
      Object.entries(clientConfig.urls).forEach(([lang, urlMap]) => {
        const filteredUrls = new Map<string, string>();
  
        if (urlMap.officeLocatorLink) {
          filteredUrls.set("officeLocatorLink", urlMap.officeLocatorLink);
        }

        if (urlMap.onlineSocialAssistanceLink) {
          filteredUrls.set("onlineSocialAssistanceLink", urlMap.onlineSocialAssistanceLink);
        }

        if (urlMap.postalCodeFinderLink) {
          filteredUrls.set("postalCodeFinderLink", urlMap.postalCodeFinderLink);
        }
  
        // Add the filtered URLs map to the injectedConfig for the current language
        injectedConfig.urls.set(lang, filteredUrls);
      });
    }
  
    return injectedConfig;
  }


  getInitId(): string {
      return this.initId;
  }
}
