import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {AppRoutingModule} from './app-routing.module';
import {ConfirmationComponent} from './pages/confirmation/confirmation.component';
import {IntakeService} from './services/intake.service';
import {IntakeServiceImpl} from './services/intake-impl.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthService} from './services/auth.service';
import {AuthServiceImpl} from './services/auth-impl.service';
import {StorageService} from './services/storage.service';
import {StorageServiceImpl} from './services/storage-impl.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ReviewComponent} from './pages/review/review/review.component';
import {PostalService} from './services/postal.service';
import {ScheduledMaintenanceService} from './services/scheduled.maintenance.service';
import {ScheduledMaintenanceServiceImpl} from './services/schmaintenance-impl.service';
import {PostalServiceImpl} from './services/postal-impl.service';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {EidLandingComponent} from './pages/eid/eid-landing/eid-landing.component';
import {EidConfirmIdentityComponent} from './pages/eid/eid-confirm-identity/eid-confirm-identity.component';
import {EidServiceImpl} from './services/eid-impl.service';
import {EidService} from './services/eid.service';
import {EidQuestionsComponent} from './pages/eid/eid-questions/eid-questions.component';
import {EidParentComponent} from './pages/eid/eid-parent/eid-parent.component';
import {BankingService} from './services/banking.service';
import {BankingServiceImpl} from './services/banking-impl.service';
import {SessionComponent} from './pages/session/session.component';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {ValidationService} from './services/validation.service';
import {ValidationServiceImpl} from './services/validation-impl.service';
import {MccssFooterComponent} from './mccss-footer/mccss-footer.component'
import {Timer} from './utils/timer';
import {ReviewQuestionComponent} from './pages/review/review-question/review-question.component';
import {DirectAccessGuard} from './direct-access-guard';
import {WdaComponent} from './pages/wda/wda/wda.component';
import {EsignatureLandingComponent} from './pages/esignature-landing/esignature-landing.component'
import {ESignatureService} from './services/esignature.service';
import {EsignatureImplService} from './services/esignature-impl.service';
import {ExternalRouter} from './external.router';
import {ConsentComponent} from './pages/consent/consent.component'
import {AdditionalSignaturesComponent} from './pages/additional-signatures/additional-signatures.component'
import {EsignUtil} from './esign-util';
import {SomethingWentWrongComponent} from './pages/something-went-wrong/something-went-wrong.component';
import {EmailService} from './services/email.service';
import {EmailImplService} from './services/email-impl.service';
import {WdaLandingComponent} from './pages/wda/wda-landing/wda-landing.component';
import {WdaParentComponent} from './pages/wda/wda-parent/wda-parent.component';
import {AppSessionService} from './services/app-session.service';
import {AppSessionServiceImpl} from './services/app-session-impl.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {BeforeYouStartOneComponent} from './pages/before-you-start-one/before-you-start-one.component';
import {BeforeYouStartTwoComponent} from './pages/before-you-start-two/before-you-start-two.component';
import {TextQuestionComponent} from './common/ui/text-question/text-question.component';
import {CheckboxQuestionComponent} from './common/ui/checkbox-question/checkbox-question.component';
import {DropdownQuestionComponent} from './common/ui/dropdown-question/dropdown-question.component';
import {DynamicPanelComponent} from './common/ui/dynamic-panel/dynamic-panel.component';
import {DynamicQuestionComponent} from './common/ui/dynamic-question/dynamic-question.component';
import {RadioQuestionComponent} from './common/ui/radio-question/radio-question.component';
import {PageContainerComponent} from './common/ui/page-container/page-container.component';
import {TextListComponent} from './common/ui/text-list/text-list.component';
import {QuestionControlService} from './common/utils/questions/question-control.service';
import {BeforeYouStartTwoQuestionService} from './pages/before-you-start-two/before-you-start-two-question.service';

import {PersonalInformationComponent} from './pages/personal-information/personal-information.component';
import {
  StatusInCanadaQuestionsComponent
} from './common/status-in-canada-questions/status-in-canada-questions.component';

import {DynamicContainerComponent} from './common/ui/dynamic-container/dynamic-container.component';
import {FinancialAssetsComponent} from './pages/financial-assets/financial-assets.component';
import {FinancialAssetsQuestionService} from './pages/financial-assets/financial-assets-question.service';

import {SpouseInformationComponent} from './pages/spouse-information/spouse-information.component';

import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';

import {ChildrenInformationComponent} from './pages/children-information/children-information.component';
import {AdditionalInformationComponent} from './pages/additional-information/additional-information.component';
import {HousingSituationComponent} from './pages/housing-situation/housing-situation.component';
import {HousingSituationQuestionService} from './pages/housing-situation/housing-situation-question.service';
import {HouseholdIncomeComponent} from './pages/household-income/household-income.component';
import {HouseHoldIncomeQuestionService} from './pages/household-income/service/household-income-question.service';

import {InteractionType, IPublicClientApplication, PublicClientApplication} from '@azure/msal-browser';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalService
} from '@azure/msal-angular';
import {msalConfig} from './auth-config';
import {UnauthorizedComponent} from './pages/unauthorized/unauthorized.component';
import {CaseWorkerAuthorizeService} from './services/caseWorkerAuthorizeService';
import {CaseWorkerAuthorizeServiceImpl} from './services/case-worker-authorize-service.impl';

import {LoginComponent} from './pages/login/login.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import {GoogleReCaptchaV2Service} from './services/google-recaptcha.service';
import {PreventDoubleClickDirective} from './common/utils/prevent-double-click.directive';
import {SharedModule} from '@shared/shared.module';
import {
  AdditionalInformationQuestionService
} from './pages/additional-information/additional-information-question.service';
import {ErrorHandlerService} from './services/error.handler';
import {tap} from 'rxjs/operators';
import {LoggingService} from './services/logging.service';
import {AppStateService} from './services/app-state.service';
import {httpInterceptorProviders} from './interceptors';
import {WindowService} from './services/window.service';

import {
  AppDataToFamilyInfoQuestions
} from './pages/additional-information/converters/app-data-to-family-info-questions';
import {FamilyInformationQuestionService} from './pages/additional-information/family-information-question.service';
import {
  AppDataToAdditionalInfoQuestions
} from './pages/additional-information/converters/app-data-to-additional-info-questions';
import {FormToAdditionalInfoData} from './pages/additional-information/converters/form-to-additional-info-data';
import {FormToFamilyInformationData} from './pages/additional-information/converters/form-to-family-information-data';
import {AdditionalInformationMapper} from './pages/additional-information/additional-information-mapper';
import {BankDetailsComponent} from './pages/bank-details/bank-details.component';

import {EarnedIncomeComponent} from './pages/earned-income/earned-income.component';
import {EarnedIncomeQuestionsService} from './pages/earned-income/service/earned-income-questions.service';
import {SponsorshipComponent} from './pages/sponsorship/sponsorship.component';
import {SponsorshipQuestionService} from './pages/sponsorship/sponsorship-question.service';
import {AscLandingComponent} from './pages/app-status/asc-landing/asc-landing.component';
import {AscResultsComponent} from './pages/app-status/asc-results/asc-results.component';
import {ApplicationStatusService} from './services/application-status.service';
import {ApplicationStatusServiceImpl} from './services/application-status-impl.service';
import {
  AppDataToQuestionsConverter,
  FormDataToAppDataConverter
} from './pages/financial-assets/financial-assets-mapper';
import {
  AppDataToFABankAccountsDynamicPanelsConverter,
  AppDataToFACashDynamicPanelsConverter,
  AppDataToFAInvestmentsDynamicPanelsConverter,
  AppDataToFAOtherAssetsDynamicPanelsConverter,
  AppDataToFAVehiclesDynamicPanelsConverter
} from './pages/financial-assets/converters/app-data-to-questions';
import {LoadingSpinnerComponent} from './common/ui/loading-spinner/loading-spinner.component';
import {AutocompletePositionDirective} from './common/utils/autocomplete-position.directive';
import {ConfigService} from './services/config.service';
import {ConfigServiceImpl} from './services/config-impl.service';
import {StartupConfig} from './startup.config';
import {InfoTextComponent} from './common/ui/info-text/info-text.component';
import {ProgressIndicatorComponent} from './common/ui/progress-indicator/progress-indicator.component';
import {NumbersOnlyDirective} from './common/utils/directives/numbers-only/numbers-only.directive';
import {
  DisableAutocompleteDirective
} from './common/utils/directives/disable-autocomplete/disable-autocomplete.directive';
import {AlertErrComponent} from './common/ui/alert-err/alert-err.component';
import {TitleStrategy} from '@angular/router';
import {CustomPageTitleStrategy} from './common/custom-page-title-strategy';
import {MccssHeaderComponent} from './mccss-header/mccss-header.component';
import {MccssSubmitButtonComponent} from './mccss-submit-button/mccss-submit-button.component';
import {WarningTextComponent} from './common/ui/warning-text/warning-text.component';
import {BackToTopButtonComponent} from './common/ui/back-to-top-button/back-to-top-button.component';
import {HintTextComponent} from './common/ui/hint-text/hint-text.component';
import {GlobalErrorComponent} from './pages/global-error/global-error.component';
import {TextAreaQuestionComponent} from './common/ui/text-area/text-area-question.component';
import {
  IncomeQuestionPanelComponent
} from './pages/earned-income/income-question-panel/income-question-panel.component';
import {ChildCarePanelComponent} from './pages/earned-income/child-care-panel/child-care-panel.component';
import {
  MultiOptionCheckboxQuestionComponent
} from './common/ui/multi-option-checkbox-question/multi-option-checkbox-question.component';
import {SdDateComponent} from './common/ui/sd-date/sd-date.component';
import {DynamicQuestionPanelComponent} from './common/ui/dynamic-question-panel/dynamic-question-panel.component';
import {AlertErrBoxComponent} from './common/ui/alert-err-box/alert-err-box.component';
import {MccssDialogTitleComponent} from './mccss-dialog-title/mccss-dialog-title.component';
import {MccssSaveContinueButtonComponent} from './mccss-save-continue-button/mccss-save-continue-button.component';
import {BrowseBackGuard} from './browse-back-guard';
import {LoginConfirmationComponent} from './pages/login-confirmation/login-confirmation.component';
import {ResumeApplicationComponent} from './pages/resume-application/resume-application.component';
import {PageService} from './services/page.service';
import {PageServiceImpl} from './services/page-impl.service';
import {RouteStateService} from './services/route-state.service';
import {RouteStateServiceImpl} from './services/route-state-impl.service';
import {CreateAccountComponent} from './pages/create-account/create-account.component';
import {
  CreateAccountReminderDialogComponent
} from './common/ui/create-account-reminder-dialog/create-account-reminder-dialog.component';
import {InitializeService} from './services/initialize.service';
import {InitializeServiceImpl} from './services/initialize-impl.service';
import {SessionStorageService} from './services/session-storage.service';
import {SessionStorageServiceImpl} from './services/session-storage-impl.service';
import {MenuService} from './services/menu.service';
import {MenuServiceImpl} from './services/menu-impl.service';
import {LoginErrorComponent} from './pages/login-error/login-error.component';
import {
  InprogressOrExpiredApplicationComponent
} from './pages/inprogress-or-expired-application/inprogress-or-expired-application.component';
import {RedirectService} from './services/redirect.service';
import {RedirectServiceImpl} from './services/redirect-impl.service';
import {EllipsisDirective} from './common/utils/directives/ellipsis/ellipsis.directive';
import {ProgramRecommendationComponent} from './pages/program-selection/program-recommendation.component';
import {ProgramCardComponent} from './pages/program-selection/program-card/program-card.component';
import {ProgramTypeService} from './services/program-type.service';
import {ProgramTypeServiceImpl} from './services/program-type-impl.service';
import {AccordionComponent} from './common/ui/accordion/accordion.component';
import {OnwAscStatusComponent} from './pages/app-status/onw-asc-status/onw-asc-status.component';
import {OdsAscStatusComponent} from './pages/app-status/ods-asc-status/ods-asc-status.component';
import {MultiProgramsAscStatusComponent} from './pages/app-status/multi-programs-asc-status/multi-programs-asc-status.component';
import {AscStatusProgressBarComponent} from './pages/app-status/asc-status-progress-bar/asc-status-progress-bar.component';
import {AscPendingComponent} from './pages/app-status/asc-pending/asc-pending.component';
import { FinancialIndependenceComponent } from './pages/financial-independence/financial-independence.component';
import { LivingWithYouPanelComponent } from './pages/housing-situation/living-with-you-panel/living-with-you-panel.component';
import { TrusteeInfoQuestionsComponent } from './common/trustee-info-questions/trustee-info-questions.component';
import { MCCSS_ADDRESS_SERVICE_CONFIG, MccssAddressInjectedConfig, PCLookupCommonUiModule } from '@mccss/pclookup-common-ui';
import { AddressConfigService } from './services/address-config.service';
import { MultiTranslateLoader } from './utils/multi-translate-loader';
import { AddressInfoComponent } from './pages/address-info/address-info.component';
import { AddressReviewComponent } from './pages/address-review/address-review.component';
import { AddressSummaryComponent } from './pages/address-summary/address-summary.component';

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        ConfirmationComponent,
        ReviewComponent,
        MccssDialogTitleComponent,
        EidLandingComponent,
        EidConfirmIdentityComponent,
        EidQuestionsComponent,
        EidParentComponent,
        SessionComponent,
        MccssFooterComponent,
        ReviewQuestionComponent,
        WdaComponent,
        EsignatureLandingComponent,
        ConsentComponent,
        AdditionalSignaturesComponent,
        SomethingWentWrongComponent,
        WdaLandingComponent,
        WdaParentComponent,
        BeforeYouStartOneComponent,
        BeforeYouStartTwoComponent,
        RadioQuestionComponent,
        TextQuestionComponent,
        CheckboxQuestionComponent,
        DropdownQuestionComponent,
        DynamicPanelComponent,
        DynamicQuestionComponent,
        PageContainerComponent,
        TextListComponent,
        PersonalInformationComponent,
        StatusInCanadaQuestionsComponent,
        DynamicContainerComponent,
        FinancialAssetsComponent,
        SpouseInformationComponent,
        ChildrenInformationComponent,
        AdditionalInformationComponent,
        HousingSituationComponent,
        UnauthorizedComponent,
        LoginComponent,
        HouseholdIncomeComponent,
        PreventDoubleClickDirective,
        EarnedIncomeComponent,
        BankDetailsComponent,
        SponsorshipComponent,
        AscLandingComponent,
        AscResultsComponent,
        OnwAscStatusComponent,
        OdsAscStatusComponent,
        MultiProgramsAscStatusComponent,
        AscStatusProgressBarComponent,
        LoadingSpinnerComponent,
        AutocompletePositionDirective,
        InfoTextComponent,
        ProgressIndicatorComponent,
        NumbersOnlyDirective,
        DisableAutocompleteDirective,
        EllipsisDirective,
        AlertErrComponent,
        MccssHeaderComponent,
        MccssSubmitButtonComponent,
        WarningTextComponent,
        BackToTopButtonComponent,
        HintTextComponent,
        GlobalErrorComponent,
        TextAreaQuestionComponent,
        IncomeQuestionPanelComponent,
        ChildCarePanelComponent,
        MultiOptionCheckboxQuestionComponent,
        SdDateComponent,
        DynamicQuestionPanelComponent,
        AlertErrBoxComponent,
        CreateAccountReminderDialogComponent,
        MccssSaveContinueButtonComponent,
        LoginConfirmationComponent,
        ResumeApplicationComponent,
        CreateAccountComponent,
        InprogressOrExpiredApplicationComponent,
        LoginErrorComponent,
        ProgramRecommendationComponent,
        ProgramCardComponent,
        AccordionComponent,
        AscPendingComponent,
        FinancialIndependenceComponent,
        LivingWithYouPanelComponent,
        TrusteeInfoQuestionsComponent,
        AddressInfoComponent,
        AddressReviewComponent,
        AddressSummaryComponent
    ],
    imports: [
        BrowserModule,
        MatAutocompleteModule,
        MatInputModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgxCaptchaModule,
        FormsModule,
        MatRadioModule,
        BrowserAnimationsModule,
        FontAwesomeModule,
        MatDialogModule,
        NgIdleKeepaliveModule.forRoot(),
        PCLookupCommonUiModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => HttpLoaderFactory(http, true),
                deps: [HttpClient]
            }
        }),
        MatFormFieldModule,
        SharedModule.forRoot()
    ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initAppFactory ,
      multi: true,
      deps: [InitializeService, LoggingService]
    },
    {
      provide: MCCSS_ADDRESS_SERVICE_CONFIG,
      useFactory: (addressConfigService: AddressConfigService): MccssAddressInjectedConfig => addressConfigService.getAddressServiceConfig(),
      deps: [AddressConfigService]
    },
    EsignUtil,
    {provide: IntakeService, useClass: IntakeServiceImpl},
    {provide: AuthService, useClass: AuthServiceImpl},
    {provide: StorageService, useClass: StorageServiceImpl},
    {provide: PostalService, useClass: PostalServiceImpl},
    {provide: EmailService, useClass: EmailImplService},
    {provide: ValidationService, useClass: ValidationServiceImpl},
    {provide: BankingService, useClass: BankingServiceImpl},
    {provide: EidService, useClass: EidServiceImpl},
    {provide: ESignatureService, useClass: EsignatureImplService},
    {provide: AppSessionService, useClass: AppSessionServiceImpl},
    {provide: CaseWorkerAuthorizeService, useClass: CaseWorkerAuthorizeServiceImpl},
    {provide: RedirectService, useClass: RedirectServiceImpl},
    {provide: Window, useValue: window},
    {provide: Timer, useClass: Timer},
    {provide: DirectAccessGuard, useClass: DirectAccessGuard},
    {provide: BrowseBackGuard, useClass: BrowseBackGuard},
    {provide: TitleStrategy, useClass: CustomPageTitleStrategy },
    {provide: ExternalRouter, useClass: ExternalRouter},
    {provide: QuestionControlService, useClass: QuestionControlService},
    {provide: BeforeYouStartTwoQuestionService, useClass: BeforeYouStartTwoQuestionService},
    {provide: FinancialAssetsQuestionService, useClass: FinancialAssetsQuestionService},
    {provide: EarnedIncomeQuestionsService, useClass: EarnedIncomeQuestionsService},
    {provide: HousingSituationQuestionService, useClass: HousingSituationQuestionService},
    {provide: AdditionalInformationQuestionService, useClass: AdditionalInformationQuestionService},
    {provide: FamilyInformationQuestionService, useClass: FamilyInformationQuestionService},
    {provide: AppDataToFamilyInfoQuestions, useClass: AppDataToFamilyInfoQuestions},
    {provide: AppDataToAdditionalInfoQuestions, useClass: AppDataToAdditionalInfoQuestions},
    {provide: FormToAdditionalInfoData, useClass: FormToAdditionalInfoData},
    {provide: FormToFamilyInformationData, useClass: FormToFamilyInformationData},
    {provide: AdditionalInformationMapper, useClass: AdditionalInformationMapper},
    {provide: ApplicationStatusService, useClass: ApplicationStatusServiceImpl},
    {provide: ErrorHandler, useClass: ErrorHandlerService},
    {provide: SponsorshipQuestionService, useClass: SponsorshipQuestionService},
    {provide: AppDataToQuestionsConverter, useClass: AppDataToQuestionsConverter},
    {provide: AppDataToFACashDynamicPanelsConverter, useClass: AppDataToFACashDynamicPanelsConverter},
    {provide: AppDataToFABankAccountsDynamicPanelsConverter, useClass: AppDataToFABankAccountsDynamicPanelsConverter},
    {provide: AppDataToFAInvestmentsDynamicPanelsConverter, useClass: AppDataToFAInvestmentsDynamicPanelsConverter},
    {provide: AppDataToFAVehiclesDynamicPanelsConverter, useClass: AppDataToFAVehiclesDynamicPanelsConverter},
    {provide: AppDataToFAOtherAssetsDynamicPanelsConverter, useClass: AppDataToFAOtherAssetsDynamicPanelsConverter},
    {provide: FormDataToAppDataConverter, useClass: FormDataToAppDataConverter},
    {provide: ConfigService, useClass: ConfigServiceImpl},
    {provide: InitializeService, useClass: InitializeServiceImpl},
    {provide: SessionStorageService, useClass: SessionStorageServiceImpl},
    {provide: StartupConfig, useClass: StartupConfig},
    {provide: ScheduledMaintenanceService, useClass: ScheduledMaintenanceServiceImpl},
    {provide: PageService, useClass: PageServiceImpl},
    {provide: MenuService, useClass: MenuServiceImpl},
    {provide: RouteStateService, useClass: RouteStateServiceImpl},
    {provide: ProgramTypeService, useClass: ProgramTypeServiceImpl},
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [ConfigService]
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {provide: HouseHoldIncomeQuestionService, useClass: HouseHoldIncomeQuestionService},
    GoogleReCaptchaV2Service,
    AppStateService,
    httpInterceptorProviders,
    WindowService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient, loadLibraryTranslations: boolean = false) {
  const loaders = [
    {
      prefix: '/assets/i18n/', // Host application's translation files
      suffix: '.json',
    },
  ];

  if (loadLibraryTranslations) {
    loaders.push({
      prefix: '/assets/library-i18n/', // Library's translation files
      suffix: '.json',
    });
  }

  return new MultiTranslateLoader(http, loaders);
}

export function initAppFactory(initializeService: InitializeService, loggingService: LoggingService) {
  return () => {
    return initializeService.initialize()
      .pipe(
        tap(() => {
          loggingService.initialize();
        }),
      )
      .toPromise();
  };
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info,
 * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(configService: ConfigService): IPublicClientApplication {
  const config = msalConfig(configService)
  return new PublicClientApplication(config);
}


/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect
  };
}
