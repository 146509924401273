export class FieldFormatUtil {
  static needsToFormatValue(fieldName: string): boolean {
    const fieldsNeedToFormatValue = ['childHealthCardNumber', 'childSocialInsuranceNumber',
      'healthCardNumber', 'phone', 'socialInsuranceNumber','trusteePhone', 'trusteeOrgPhone',
      'spouseHealthCardNumber', 'spouseSocialInsuranceNumber'];
    if (!fieldName) {
      return false;
    }
    return fieldsNeedToFormatValue.includes(fieldName);
  }
}
