import {Injectable} from '@angular/core';
import {Question} from 'src/app/common/utils/questions/question';
import {
  requiredIfOtherCheckBoxesAreFalse,
  requiredIfOtherCheckBoxIsFalse,
  validateODSProgramForSingleApplicant,
  validateReceivedSocialAssistanceApplicationDate
} from 'src/app/common/utils/validators/custom-validators';
import {SadaCustomValidator} from 'src/app/validator/sada-custom-validator';
import {ApplicationAnswers} from '../../models/data.model';
import {UrlInfo} from '../../models/url-map';
import {ProgramType} from '../../models/program-type';
import {enableReceivingACSDFamilyFn} from './family-information-question.service';

@Injectable()
export class AdditionalInformationQuestionService {

  getAdditionalInformationQuestions(applicationAnswers: ApplicationAnswers): Question<string>[] {
    return [
      {
        controlType: 'radio',
        key: 'additionalReceivedSocialAssistanceInPast',
        label: 'additional-information.singleApplicant.receivedSocialAssistance',
        hintText: {text: 'additional-information.singleApplicant.receivedSocialAssistance-hintText', expandableContent: 'additional-information.singleApplicant.receivedSocialAssistance-hintContent'},
        required: true,
        needMarginTop: false,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'additionalReceivedSocialAssistanceInPast',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        backGroundClass: 'sada-checkbox-background-grey',
        displayInNewPanel: true,
        displayWithInnerCard: true,
        key: 'additionalSocialAssistanceRecipientsPanel',
        needMarginTop: false,
        panels: [{
          name: 'additionalSocialAssistanceRecipientsPanel',
          questions: this.getSocialAssistanceRecipientQuestions(applicationAnswers),
          showAddButton: false
        }],
        visibleOnConditions: [
          {
            questionKey: 'additionalReceivedSocialAssistanceInPast',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'additionalCurrentlyResidingInAnInstitution',
        label: 'additional-information.currentlyResidingInAnInstitution',
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'additionalCurrentlyResidingInAnInstitution',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        backGroundClass: 'sada-checkbox-background-grey',
        displayInNewPanel: true,
        displayWithInnerCard: true,
        key: 'additionalLivingInAnInstitutionPanel',
        needMarginTop: false,
        panels: [{
          name: 'additionalLivingInAnInstitutionPanel',
          questions: this.getLivingInAnInstitutionPanelQuestions(),
          showAddButton: false
        }],
        visibleOnConditions: [
          {
            questionKey: 'additionalCurrentlyResidingInAnInstitution',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'additionalCurrentlyIncarcerated',
        label: 'additional-information.currentlyIncarcerated',
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'panel',
        backGroundClass: 'sada-checkbox-background-grey',
        displayInNewPanel: true,
        displayWithInnerCard: true,
        key: 'additionalCurrentlyIncarceratedPanel',
        needMarginTop: false,
        panels: [{
          name: 'additionalCurrentlyIncarceratedPanel',
          questions: this.getCurrentlyIncarceratedQuestions(),
          showAddButton: false
        }],
        visibleOnConditions: [
          {
            questionKey: 'additionalCurrentlyIncarcerated',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'additionalAccommodationServices',
        label: 'additional-information.accommodationServices',
        hintText: {text: 'additional-information.accommodationServices-hintText'},
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'additionalAccommodationServices',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        backGroundClass: 'sada-checkbox-background-grey',
        displayInNewPanel: true,
        displayWithInnerCard: true,
        key: 'accommodationServicesPanel',
        panels: [{
          name: 'accommodationServicesPanel',
          questions: this.getAdditionalAccommodationServicesDetailQuestions(),
          showAddButton: false,
        }],
        visibleOnConditions: [
          {
            questionKey: 'additionalAccommodationServices',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'additionalSpecialDietOrMedicalCondition',
        label: 'additional-information.specialDietOrMedicalCondition',
        hintText: {text: 'additional-information.diabetic.hintText', expandableContent: 'additional-information.diabetic.hintContent'},
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'radio',
        key: 'personWithDisability',
        label: 'additional-information.hasDisability',
        removeLabelMargin: true,
        hintText: {boldHintLabel: 'additional-information.hasDisability-hintLabel', removeHintLabelMargin: true, text: 'additional-information.hasDisability-hintContent'},
        required: true,
        needMarginTop: true,
        innerHtmlError: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'validatorFn',
            validatorFn: (value) => SadaCustomValidator.validateDisabilityBasedOnFirstNation(value,
              applicationAnswers.jsonData.livingOnFirstNationsReserveLand),
            errorKey: 'additional-information.livingOnFirstNationsReserveLand.error',
            errorKeyParam: {link: [UrlInfo.officeLocation, true]}
          }
        ]
      },
      {
        controlType: 'panel',
        backGroundClass: 'sada-checkbox-background-grey',
        displayInNewPanel: true,
        displayWithInnerCard: true,
        key: 'disabilityProgramQuestionsPanel',
        needMarginTop: false,
        panels: [{
          name: 'disabilityProgramQuestionsPanel',
          questions: this.getDSOQuestion(),
          showAddButton: false,
        }],
        visibleOnConditions: [
          {
            questionKey: 'personWithDisability',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'receivingACSD',
        label: 'additional-information.singleApplicant.receivingACSD',
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ],
        visibleOnConditions: [
          {
            customFunction: enableReceivingACSDFamilyFn(applicationAnswers)
          }
        ]
      },
      {
        controlType: 'radio',
        key: 'additionalNutritionalNeeds',
        label: 'additional-information.nutritionalNeeds',
        hintText: {text: 'additional-information.nutrition.hintText', expandableContent: 'additional-information.nutrition.hintContent'},
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'additionalNutritionalNeeds',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        backGroundClass: 'sada-checkbox-background-grey',
        displayInNewPanel: true,
        displayWithInnerCard: true,
        key: 'additionalNutritionalNeedsPanel',
        needMarginTop: false,
        panels: [{
          name: 'additionalNutritionalNeedsPanel',
          questions: this.getAdditionalNutritionalNeedsPanelQuestions(),
          showAddButton: false,
        }],
        visibleOnConditions: [
          {
            questionKey: 'additionalNutritionalNeeds',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'additionalFullTimeStudent',
        label: 'additional-information.singleApplicant.fullTimeStudent',
        hintText: {text: 'additional-information.singleApplicant.student.hintText'},
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
        visibleOnConditions: [
          {
            questionKey: 'additionalNutritionalNeeds',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'panel',
        backGroundClass: 'sada-checkbox-background-grey',
        displayInNewPanel: true,
        displayWithInnerCard: true,
        key: 'additionalSchoolType',
        needMarginTop: false,
        panels: [{
          name: 'additionalNutritionalNeedsPanel',
          questions: this.getAdditionalSchoolTypePanelQuestions(applicationAnswers),
          showAddButton: false,
        }],
        visibleOnConditions: [
          {
            questionKey: 'additionalFullTimeStudent',
            questionControlType: 'radio',
            value: ['yes']
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'additionalCaringForChild',
        label: 'additional-information.caringForChild',
        required: false,
        needMarginTop: true,
        hintText: {text: 'additional-information.temporaryCare.hintText', expandableContent: 'additional-information.temporaryCare.hintContent'},
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }]
      },
    ];
  }

  getSocialAssistanceRecipientQuestions(applicationAnswers: any) {
    const applicantDateOfBirth = applicationAnswers.jsonData.dateOfBirth
    const trimmedDateOfBirth = applicantDateOfBirth?.slice(0, applicantDateOfBirth.lastIndexOf('/'));  // Only interested in 'YYYY/MM'.
    const saQuestions: Question<string>[] = [
      {
        controlType: 'radio',
        key: 'additionalReceivedSocialAssistanceProgram',
        label: 'additional-information.singleApplicant.socialAssistancePanel.receivedSocialAssistanceProgram',
        required: true,
        hintText: {
          text: 'additional-information.singleApplicant.socialAssistancePanel.receivedSocialAssistanceProgram.hintText',
          expandableContent: 'additional-information.singleApplicant.socialAssistancePanel.receivedSocialAssistanceProgram.hintContent'},
        options: [{
          value: ProgramType.ODS,
          label: 'choices.radioDefault.odsp'
        },
          {
            value: ProgramType.ONW,
            label: 'choices.radioDefault.ow'
          },
          {
            value: ProgramType.ACSD,
            label: 'choices.radioDefault.acsd'
          }],
        innerHtmlError: true,
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'crossField',
            validatorFn: validateODSProgramForSingleApplicant(applicationAnswers?.jsonData?.moneyForImmediateNeed),
            errorKey: 'error.reopenPastODSApplication',
            errorKeyParam: {link: [UrlInfo.officeLocation, true]}
          }
        ]
      },
      {
        controlType: 'odsdate',
        key: 'additionalApplicationDate',
        label: 'additional-information.singleApplicant.socialAssistancePanel.lastReceivedAssistanceDate',
        skipContainerLabel: true,
        required: true,
        needMarginTop: true,
        showDay: false,
        validators: [
          {
            type: 'validatorFn',
            params: [trimmedDateOfBirth],
            validatorFn: SadaCustomValidator.validateDateWithMinAndFuture,
            errorKey: 'additional-information.error.invalid.additionalApplicationDate'
          },
          {
            type: 'crossField',
            validatorFn: validateReceivedSocialAssistanceApplicationDate('additionalApplicationDate', 'additionalReceivedSocialAssistanceProgram', 'Applicant'),
            errorKey: null
          }
        ]
      },
      {
        controlType: 'textbox',
        key: 'additionalMemberID',
        label: 'additional-information.memberID',
        required: false,
        needMarginTop: true,
        characterWidth: 20,
        hintText: {text: 'additional-information.memberID.hintText', expandableContent: 'additional-information.memberID.hintContent'},
        validators: [
          {
            type: 'validatorFn',
            validatorFn: SadaCustomValidator.validateMemberID,
            errorKey: 'additional-information.error.invalid.memberId'
          }
        ]
      }
    ]
    return saQuestions;
  }

  getLivingInAnInstitutionPanelQuestions() {
    const ipQuestions: Question<string>[] = [
      {
        controlType: 'dropdown',
        key: 'additionalLivingInAnInstitutionDropdown',
        label: 'additional-information.livingInAnInstitutionPanel.whereAreYouStaying',
        required: true,
        needMarginTop: false,
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ],
        options: [
          {
            value: 'INSTITUTION_CAMH',
            label: 'additional-information.livingInAnInstitutionPanel.camh'
          },
          {
            value: 'INSTITUTION_HOMEWOOD_HEALTH',
            label: 'additional-information.livingInAnInstitutionPanel.homewoodHealthCentre'
          },
          {
            value: 'INSTITUTION_HOSPITAL',
            label: 'additional-information.livingInAnInstitutionPanel.hospitalOrMHCentre'
          },
          {
            value: 'INSTITUTION_LONG_TERM_CARE',
            label: 'additional-information.livingInAnInstitutionPanel.longTermCare'
          },
          {
            value: 'INSTITUTION_SUBSTANCE_ABUSE_PROGRAM',
            label: 'additional-information.livingInAnInstitutionPanel.substanceAbuse'
          },
          {
            value: 'INSTITUTION_WOMENS_SHELTER',
            label: 'additional-information.livingInAnInstitutionPanel.womensShelter'
          },
          {
            value: 'INSTITUTION_SPECIAL_CARE',
            label: 'additional-information.livingInAnInstitutionPanel.specialCare'
          },
          {
            value: 'INSTITUTION_GROUP_RESIDENCE',
            label: 'additional-information.livingInAnInstitutionPanel.groupResidence'
          },
          {
            value: 'INSTITUTION_SUPPORTED_RESIDENCE',
            label: 'additional-information.livingInAnInstitutionPanel.supportedResidence'
          },
          {
            value: 'INSTITUTION_SCHOOL_HEARING_IMPAIRED',
            label: 'additional-information.livingInAnInstitutionPanel.hearingImpaired'
          },
          {
            value: 'INSTITUTION_SCHOOL_VISION_IMPAIRED',
            label: 'additional-information.livingInAnInstitutionPanel.visionImpaired'
          },
          {
            value: 'INSTITUTION_SCHOOL_FAMILY_SERVICES',
            label: 'additional-information.livingInAnInstitutionPanel.familyServices'
          }
        ]
      },
      {
        controlType: 'textbox',
        key: 'additionalInstitutionName',
        label: 'additional-information.livingInAnInstitutionPanel.additionalInstitutionName',
        capitalizeFirstLetter: true,
        required: true,
        needMarginTop: true,
        characterWidth: 20,
        visibleOnConditions: [
          {
            questionKey: 'additionalLivingInAnInstitutionDropdown',
            questionControlType: 'dropdown',
            value: ['', 'INSTITUTION_HOSPITAL', 'INSTITUTION_LONG_TERM_CARE', 'INSTITUTION_SUBSTANCE_ABUSE_PROGRAM',
              'INSTITUTION_WOMENS_SHELTER', 'INSTITUTION_SPECIAL_CARE', 'INSTITUTION_GROUP_RESIDENCE', 'INSTITUTION_SUPPORTED_RESIDENCE',
              'INSTITUTION_SCHOOL_HEARING_IMPAIRED', 'INSTITUTION_SCHOOL_VISION_IMPAIRED', 'INSTITUTION_SCHOOL_FAMILY_SERVICES']
          }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'validatorFn',
            validatorFn: SadaCustomValidator.validateInstitutionName,
            errorKey: 'additional-information.error.invalid.institutionName'
          },
          {
            type: 'maxLength',
            maxLengthValue: 65,
            errorKey: 'additional-information.error.invalid.institutionName.length'
          }
        ]
      }
    ];

    return ipQuestions;
  }

  getCurrentlyIncarceratedQuestions() {
    const incarceratedQuestions: Question<string>[] = [
      {
        controlType: 'odsdate',
        key: 'incarceratedExpectedReleaseDate',
        label: 'additional-information.currentlyIncarceratedPanel.expectedReleaseDate',
        dateSmallRightMargin: true,
        skipContainerLabel: true,
        showDay: true,
        required: true,
        validatorFns: [
          {
            validationFunction: (v: []) => {
              return SadaCustomValidator.isValidDate(v) && !SadaCustomValidator.isInPastExcludeToday(v);
            },
            errorKey: 'error.invalid.date'
          },
          {
            validationFunction: (v: []) => {
              return SadaCustomValidator.isInPastExcludeToday(v) || SadaCustomValidator.isDateWithinDaysIncludeToday([...v, '10']);
            },
            errorKey: 'additional-information.error.invalid.dateOfRelease.pastTenDays'
          }
        ]
      },
      {
        controlType: 'radio',
        key: 'incarceratedStayPartTimeOrFullTime',
        label: 'additional-information.currentlyIncarceratedPanel.stayPartTimeOrFullTime',
        required: true,
        needMarginTop: true,
        options: [{
          value: 'full-time',
          label: 'choices.radioDefault.fullTime'
        },
          {
            value: 'part-time',
            label: 'choices.radioDefault.partTime'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      }
    ];

    return incarceratedQuestions;
  }

  getAdditionalAccommodationServicesDetailQuestions(): Question<any>[] {
    return [
      {
        controlType: 'checkbox',
        key: 'visualCheckbox',
        isRequiredRelatedCrossFieldValidator: true,
        options: [{
          value: false,
          label: 'additional-information.multipleApplicants.visualSupport'
        }],
        validators: [
          {
            type: 'crossField',
            validatorFn: requiredIfOtherCheckBoxesAreFalse('visualCheckbox', ['hearingCheckbox','verbalCheckbox','communicationCheckbox','mobilityCheckbox','visualCheckbox']),
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'textarea',
        key: 'visualDescription',
        label: 'additional-information.pleaseDescribe',
        displayCharsRemaining: 250,
        needMarginBottom: true,
        needMarginLeft: true,
        required: false,
        visibleOnConditions: [
          {
            questionKey: 'visualCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          }],
        validators: [
          {
            type: 'maxLength',
            maxLengthValue: 250,
            errorKey: 'additional-information.error.desc.minMaxLength'
          },
          {
            type: 'minLength',
            minLengthValue: 1,
            errorKey: 'additional-information.error.desc.minMaxLength'
          }
        ]
      },
      {
        controlType: 'checkbox',
        key: 'hearingCheckbox',
        isRequiredRelatedCrossFieldValidator: true,
        options: [{
          value: false,
          label: 'additional-information.multipleApplicants.hearingSupport',
        }],
        validators: [
          {
            type: 'crossField',
            validatorFn: requiredIfOtherCheckBoxesAreFalse('hearingCheckbox', ['visualCheckbox','verbalCheckbox','communicationCheckbox','mobilityCheckbox','visualCheckbox']),
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'textarea',
        key: 'hearingDescription',
        label: 'additional-information.pleaseDescribe',
        displayCharsRemaining: 250,
        needMarginBottom: true,
        needMarginLeft: true,
        required: false,
        visibleOnConditions: [
          {
            questionKey: 'hearingCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          }],
        validators: [
          {
            type: 'maxLength',
            maxLengthValue: 250,
            errorKey: 'additional-information.error.desc.minMaxLength'
          },
          {
            type: 'minLength',
            minLengthValue: 1,
            errorKey: 'additional-information.error.desc.minMaxLength'
          }
        ]
      },
      {
        controlType: 'checkbox',
        key: 'verbalCheckbox',
        isRequiredRelatedCrossFieldValidator: true,
        options: [{
          value: false,
          label: 'additional-information.multipleApplicants.verbalSupport',
        }],
        validators: [
          {
            type: 'crossField',
            validatorFn: requiredIfOtherCheckBoxesAreFalse('verbalCheckbox', ['hearingCheckbox','visualCheckbox','communicationCheckbox','mobilityCheckbox','visualCheckbox']),
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'textarea',
        key: 'verbalDescription',
        label: 'additional-information.pleaseDescribe',
        displayCharsRemaining: 250,
        needMarginBottom: true,
        needMarginLeft: true,
        required: false,
        visibleOnConditions: [
          {
            questionKey: 'verbalCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          }],
        validators: [
          {
            type: 'maxLength',
            maxLengthValue: 250,
            errorKey: 'additional-information.error.desc.minMaxLength'
          },
          {
            type: 'minLength',
            minLengthValue: 1,
            errorKey: 'additional-information.error.desc.minMaxLength'
          }
        ]
      },
      {
        controlType: 'checkbox',
        key: 'communicationCheckbox',
        isRequiredRelatedCrossFieldValidator: true,
        options: [{
          value: false,
          label: 'additional-information.multipleApplicants.communicationSupport',
        }],
        validators: [
          {
            type: 'crossField',
            validatorFn: requiredIfOtherCheckBoxesAreFalse('communicationCheckbox', ['hearingCheckbox','verbalCheckbox','visualCheckbox','mobilityCheckbox','visualCheckbox']),
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'textarea',
        key: 'communicationDescription',
        label: 'additional-information.pleaseDescribe',
        hintText: {text: 'additional-information.multipleApplicants.communicationSupport-hintText'},
        displayCharsRemaining: 250,
        needMarginBottom: true,
        needMarginLeft: true,
        required: false,
        visibleOnConditions: [
          {
            questionKey: 'communicationCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          }],
        validators: [
          {
            type: 'maxLength',
            maxLengthValue: 250,
            errorKey: 'additional-information.error.desc.minMaxLength'
          },
          {
            type: 'minLength',
            minLengthValue: 1,
            errorKey: 'additional-information.error.desc.minMaxLength'
          }
        ]
      },
      {
        controlType: 'checkbox',
        key: 'mobilityCheckbox',
        isRequiredRelatedCrossFieldValidator: true,
        options: [{
          value: false,
          label: 'additional-information.multipleApplicants.mobilitySupport',
        }],
        validators: [
          {
            type: 'crossField',
            validatorFn: requiredIfOtherCheckBoxesAreFalse('mobilityCheckbox', ['hearingCheckbox','verbalCheckbox','communicationCheckbox','visualCheckbox','visualCheckbox']),
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'textarea',
        key: 'mobilityDescription',
        label: 'additional-information.pleaseDescribe',
        displayCharsRemaining: 250,
        needMarginLeft: true,
        required: false,
        visibleOnConditions: [
          {
            questionKey: 'mobilityCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          }],
        validators: [
          {
            type: 'maxLength',
            maxLengthValue: 250,
            errorKey: 'additional-information.error.desc.minMaxLength'
          },
          {
            type: 'minLength',
            minLengthValue: 1,
            errorKey: 'additional-information.error.desc.minMaxLength'
          }
        ]
      }
    ];
  }

  getDSOQuestion(): Question<string>[] {
    return [
      {
        controlType: 'radio',
        key: 'developmentServicesOntario',
        label: 'additional-information.singleApplicant.developmentServicesOntario',
        required: true,
        // needMarginTop: true,
        options: [
          {
          value: 'approved',
          label: 'choices.radioDefault.approved'
          },
          {
            value: 'applied',
            label: 'choices.radioDefault.applied'
          },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      }
    ];
  }

  getAdditionalNutritionalNeedsPanelQuestions(): Question<string>[] {
    return [
      {
        controlType: 'checkbox',
        key: 'pregnantCheckbox',
        label:'additional-information.describesYourSituation',
        hintText: {text: 'additional-information.select.all'},
        required: true,
        options: [{
          value: false,
          label: 'additional-information.pregnant',
        }],
        validators: [
          {
            type: 'crossField',
            validatorFn: requiredIfOtherCheckBoxIsFalse('pregnantCheckbox', 'breastFeedingCheckbox'),
            errorKey: 'error.required'
          }
        ],
        disableOthersOnCondition: [
          {
            questionKey: 'dueDate',
            questionControlType: 'textbox',
            index: 0,
            value: [false],
          },
        ]
      },
      {
        controlType: 'checkbox',
        key: 'breastFeedingCheckbox',
        options: [{
          value: false,
          label: 'additional-information.breastFeeding',
        }]
      },
      {
        controlType: 'space',
        spaceClass: 'ontario-margin-bottom-16-!',
        key: 'whatever',
      },
      {
        controlType: 'odsdate',
        key: 'dueDate',
        label: 'additional-information.pregnantDueDate',
        dateSmallRightMargin: true,
        skipContainerLabel: true,
        showDay: true,
        required: true,
        validatorFns: [
          {
            validationFunction: (value: []) => {
              return SadaCustomValidator.isValidPregnancyDueDate(value);
            },
            errorKey: 'additional-information.error.invalid.pregnancyDueDate'
          }
        ],
        visibleOnConditions: [
          {
            questionKey: 'pregnantCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          },
        ]
      },
      // {
      //   controlType: 'space',
      //   spaceClass: 'ontario-margin-bottom-16-!',
      //   key: 'whatever',
      // },
      {
        controlType: 'radio',
        key: 'lactoseIntolerantDiet',
        label: 'additional-information.lactoseIntolerantDiet',
        required: true,
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
        },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        visibleOnAnyConditions: [
          {
            questionKey: 'pregnantCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          },
          {
            questionKey: 'breastFeedingCheckbox',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      }
    ];
  }

  getAdditionalSchoolTypePanelQuestions(applicationAnswers) {
    return [
      {
        controlType: 'dropdown',
        key: 'additionalSchoolType',
        label: 'additional-information.singleApplicant.school',
        labelParams: [applicationAnswers.jsonData.firstName, applicationAnswers.jsonData.lastName],
        required: true,
        needMarginTop: false,
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ],
        options: [
          {
            value: 'secondarySchool',
            label: 'additional-information.secondarySchool'
          },
          {
            value: 'postSecondarySchool',
            label: 'additional-information.postSecondarySchool'
          }
        ]
      },
    ]
  }
}
