import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlContainer, FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {QuestionBaseComponent} from '../QuestionBaseComponent';

@Component({
  selector: 'sd-checkbox-question',
  templateUrl: './checkbox-question.component.html',
  styleUrls: ['./checkbox-question.component.scss']
})
export class CheckboxQuestionComponent extends QuestionBaseComponent implements OnInit, AfterViewInit {
  form: FormGroup;

  @Input()
  showLabel = true;
  @Input()
  public id: string | undefined;
  @Input()
  public options: CheckboxItem[] = [];
  @Input()
  backgroundClass: string;
  @Input()
  public labelParam: any | {};
  @Input()
  public showCustomError: string | undefined;
  @Input()
  public applyStylingToLabel: boolean;
  @Input()
  public required: boolean;

  @Output()
  toggle = new EventEmitter<any[]>();

  constructor(public formBuilder: FormBuilder, public controlContainer: ControlContainer) {
    super(controlContainer, formBuilder);
    this.form = this.formBuilder.group({
      items: new FormArray([])
    });
  }

  ngOnInit() {
    this.populateCheckBoxes();

    this.form.valueChanges.subscribe(value => {
      const optionsChecked = new Array<any>();
      for (let index = 0; index < this.items.length; index++) {
        // @ts-ignore
        const isOptionChecked = this.items.get(index.toString()).value;
        if (isOptionChecked) {
          const currentOptionValue = this.options[index].value;
          optionsChecked.push(currentOptionValue)
        } else {
        }
      }
      this.toggle.emit(optionsChecked);
    });

    this.setupAttributeAndValue();

  }

  // tslint:disable-next-line:typedef
  populateCheckBoxes() {
    if (this.items.length === 0) {
      this.options.forEach(x => {
        this.items.push(new FormControl(x.checked));
      });
    }
    this.options.forEach(item => {
      const index: number = this.options.findIndex(opt => opt.value === item.value);
      if (index >= 0 && item.checked) {
        // @ts-ignore
        this.items.get(index.toString()).setValue(true);
      }
    });
  }

  private setupAttributeAndValue(): void {
    if (this.disabled) {
      const formArray = this.form.controls.items as FormArray;
      formArray?.controls.forEach((control) =>{
        control.disable();
      })
    }
  }

  ngAfterViewInit() {
    // this.setBackgroundClass();
  }

  get items(): FormArray {
    return this.form.get('items') as FormArray;
  }

  get fieldName(): string {
    return this.id?.substr(this.id.indexOf('.') + 1);
  }

  setBackgroundClass() {
    if (this.showCustomError) {
      return 'ontario-checkboxes__error'
    } else {
      return this.backgroundClass
    }
  }
}

export interface CheckboxItem {
  value: string;
  label: string;
  labelParam?: any;
  checked: boolean;
}


