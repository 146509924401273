import {ProgramType} from '../../../models/program-type';

export interface Choice {
  value?: any;
  label: string;
  labelParam?: any;
  alternateValue?: string;
  disabled? : boolean;
  lang?: string;
  relatedValue?: any;
}

export const StatusInCanada: Choice[] = [
  {value: 'Canadian citizen born in Canada', label: 'choices.statusInCanada.canadian.born.canada'},
  {value: 'Status Indian', label: 'choices.statusInCanada.status.indian'},
  {value: 'Canadian citizen not born in Canada', label: 'choices.statusInCanada.canadian.not.born.canada'},
  {value: 'Permanent resident', label: 'choices.statusInCanada.permanent.resident'},
  {value: 'Applicant for permanent residence', label: 'choices.statusInCanada.applicant.for.residence'},
  {value: 'Convention refugee', label: 'choices.statusInCanada.convention.refugee'},
  {value: 'Refugee claimant', label: 'choices.statusInCanada.refugee.claimant'},
  {value: 'Government assisted refugee', label: 'choices.statusInCanada.refugee.government.assisted.claimant'},
  {value: 'Deportee', label: 'choices.statusInCanada.deportee'},
  {value: 'Temporary resident permit', label: 'choices.statusInCanada.temporary.resident.permit'},
  {value: 'Visitor or tourist', label: 'choices.statusInCanada.visitor.or.tourist'},
  {value: 'Student visa', label: 'choices.statusInCanada.student.visa'}
];

export const MaritalStatus: Choice[] = [
  {value: 'Single', label: 'choices.maritalStatus.single'},
  {value: 'Divorced', label: 'choices.maritalStatus.divorced'},
  {value: 'Separated', label: 'choices.maritalStatus.separated'},
  {value: 'Common Law', label: 'choices.maritalStatus.commonLaw'},
  {value: 'Married', label: 'choices.maritalStatus.married'},
  {value: 'Widowed', label: 'choices.maritalStatus.widowed'}
]

export const StatusNotEligibleForSupport: string[] = [
  'Temporary resident permit',
  'Visitor or tourist',
  'Student visa'
];

export const ApplyFor: Choice[] = [
  {value: 'APPLICATION_SELF', label: 'choices.applyingForSomeoneElse.myself'},
  {value: 'APPLICATION_OTHER', label: 'choices.applyingForSomeoneElse.other'},
];

export const ReceivedSocialAssistanceProgram: Choice[] = [
  {value: ProgramType.ODS, label: 'choices.radioDefault.odsp'},
  {value: ProgramType.ONW, label: 'choices.radioDefault.ow'},
  {value: ProgramType.ACSD, label: 'choices.radioDefault.acsd'},
]

export const NeedTrustee: Choice[] = [
  {value: 'trusteeIdentified', label: 'choices.need.trustee.yes.identified'},
  {value: 'trusteeNotIdentified', label: 'choices.need.trustee.yes.notIdentified'},
  {value: 'no', label: 'choices.need.trustee.no'},
];

export const NeedOrganizationTrustee: Choice[] = [
  {value: 'trusteeIdentified', label: 'choices.radioDefault.yes'},
  {value: 'no', label: 'choices.radioDefault.no'},
];

export const DefaultRadio: Choice[] = [
  {value: 'yes', label: 'choices.radioDefault.yes'},
  {value: 'no', label: 'choices.radioDefault.no'},
];

export const DefaultRevertedRadio: Choice[] = [
  {value: 'no', label: 'choices.radioDefault.yes'},
  {value: 'yes', label: 'choices.radioDefault.no'},
];

export const Languages: Choice[] = [
  {value: 'english', label: 'choices.language.english'},
  {value: 'french', label: 'choices.language.french'},
];

export const Sexes: Choice[] = [
  {value: 'male', label: 'choices.genderAtBirth.male'},
  {value: 'female', label: 'choices.genderAtBirth.female'},
];

export const IncomeTypes: Choice[] = [
  {value: 'fullTime', label: 'earned-income.income.type.choices.full.time'},
  {value: 'partTime', label: 'earned-income.income.type.choices.part.time'},
  {value: 'selfEmployed', label: 'earned-income.income.type.choices.self.employment'},
  {value: 'training', label: 'earned-income.income.type.choices.training'}
]

export const ChildCareIncomeTypes: Choice[] = [
  {value: 'beforeAfterSchoolProgram', label: 'earned-income.child.care.income.program.type.school.program'},
  {value: 'licensedChildCare', label: 'earned-income.child.care.income.program.type.licensed.program'},
  {value: 'unlicensedChildCare', label: 'earned-income.child.care.income.program.type.unlicensed.program'}
]
