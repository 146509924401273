import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ControlContainer, FormBuilder, FormGroup} from '@angular/forms';
import {Languages} from '../utils/questions/question-choices';
import {ValidationService} from '../../services/validation.service';
import {ConfigService} from '../../services/config.service';
import {TranslateService} from '@ngx-translate/core';
import {TrusteeFormControlConfigs} from './util/trustee-info-component.formcontrols'
import {Subscription} from 'rxjs';
import {TrusteeType} from '../../models/trustee-type';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';
import {NoLastNamePopup} from '../../pages/personal-information/personal-information.component';
import {MccssDialogTitleComponent} from '../../mccss-dialog-title/mccss-dialog-title.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {DataType} from '../ui/text-question/util/text-question-component.util';
import {ValidationFnsUtil} from '../../utils/validation-fns-util';
import {EmailService} from '../../services/email.service';
import {TrusteeInfoComponentUtil} from './util/trustee-info-component.util';
import {nameCharacterPattern} from '../utils/validators/custom-validators';

@Component({
  selector: 'sd-trustee-info-questions',
  templateUrl: './trustee-info-questions.component.html',
  styleUrls: ['./trustee-info-questions.component.scss']
})
export class TrusteeInfoQuestionsComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public showError: boolean;
  @Input()
  public applicationAnswers: any
  @Input()
  public trusteeType: any
  @Input()
  isAuthorizedUser: boolean
  @Input()
  dialogVisible: boolean

  readonly dataType = DataType;
  readonly customRequiredErrorMessage = 'error.empty.email.address';
  readonly trusteeIdentificationNumberPattern = '^\\d+$'

  subscriptions$: Subscription[] = [];
  form: FormGroup;
  formControlNames: any;
  needTrustee;
  languages = Languages;
  firstNameValidationFns;
  lastNameValidationFns;
  emailValidationFns;
  emailAsyncValidationFns;
  trusteeIdentificationValidationFns;

  trusteeIdentified = false;
  noLastNameCheckBoxItems;
  nameCharacterPattern = nameCharacterPattern

  constructor(public controlContainer: ControlContainer,
              public formBuilder: FormBuilder,
              public validationService: ValidationService,
              public configService: ConfigService,
              private emailService: EmailService,
              public translator: TranslateService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.form = this.controlContainer?.control as FormGroup
    this.formControlNames = TrusteeFormControlConfigs[this.trusteeType]
    // tslint:disable-next-line:max-line-length
    this.noLastNameCheckBoxItems = [{value: 'yes', label: 'start-two.trusteeInfo.' + this.trusteeType + '.trusteeNoLastName', checked: false}]
    this.addInitialFormControls()
    this.setupValidators()

    this.setUpInitialValues();
  }

  ngOnChanges(changes:SimpleChanges){}

  ngOnDestroy() {
    this.subscriptions$.forEach(s => s.unsubscribe());
    this.dialog.closeAll()
  }

  onNeedTrusteeChange(value: any) {
    if(value === 'trusteeIdentified'){
      this.trusteeIdentified = true;
    } else {
      this.trusteeIdentified = false;
    }
  }

  isNoLastNameSelected() {
    return this.form.controls.trusteeNoLastName?.value?.length
  }

  onNoLastNameCheckBoxChanges(event: any): void {
    const trusteeLastNameControlName = this.formControlNames.trusteeLastName.name
    const trusteeNoLastNameControlName = this.formControlNames.trusteeNoLastName.name
    if (event && event.length) {
      this.form.controls[trusteeNoLastNameControlName].setValue(event)
      this.form.controls[trusteeLastNameControlName].reset();
      this.form.controls[trusteeLastNameControlName].disable();
      this.openNoLastNameDialog(NoLastNamePopup.noLastName)
    } else {
      delete this.applicationAnswers.jsonData[trusteeNoLastNameControlName]
      this.form.controls[trusteeNoLastNameControlName].reset()
      this.form.controls[trusteeLastNameControlName].enable()
    }
  }

  private setupValidators(){
    this.firstNameValidationFns = [
      {
        validationFunction: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
        errorKey: 'error.invalid.firstNameWithOnlyCharacters'
      },
      {
        validationFunction: SadaCustomValidator.validateName,
        errorKey: 'error.invalid.firstName'
      },
      {
        validationFunction: SadaCustomValidator.validateInvalidFrCharacterInName,
        errorKey: 'error.invalid.fr.character'
      }
    ];
    this.lastNameValidationFns = [
      {
        validationFunction: SadaCustomValidator.validateInvalidNameWithOnlySpecialCharacter,
        errorKey: 'error.invalid.lastNameWithOnlyCharacters'
      },
      {
        validationFunction: SadaCustomValidator.validateName,
        errorKey: 'error.invalid.lastName'
      },
      {
        validationFunction: SadaCustomValidator.validateInvalidFrCharacterInName,
        errorKey: 'error.invalid.fr.character'
      }
    ];
    this.emailValidationFns = ValidationFnsUtil.emailValidationFns(
      this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse, this.isAuthorizedUser);
    this.emailAsyncValidationFns = [
      {
        validationFunction: (value) => this.emailService.validateEmailAddress(value),
        errorKey: 'error.invalid.email.domain'
      }
    ];
    this.trusteeIdentificationValidationFns = [
      {
        validationFunction: SadaCustomValidator.validateTrusteeIdentificationNum, errorKey: 'start-two.trusteeInfo.organizationTrustee.trusteeIdentificationNumber.error.invalid.number'
      }
    ];
  }

  private addInitialFormControls(): any {
    TrusteeInfoComponentUtil.addTrusteeInfoFormControls(this.form, this.formBuilder, this.trusteeType);
    TrusteeInfoComponentUtil.removeTrusteeInfoFormControls(this.form, this.formBuilder, this.applicationAnswers,
      this.trusteeType === TrusteeType.ORGANIZATION_TRUSTEE ? TrusteeType.INDIVIDUAL_TRUSTEE : TrusteeType.ORGANIZATION_TRUSTEE)
  }

  private openNoLastNameDialog(content): void {
    if (this.dialogVisible) {
      return;
    }
    this.dialogVisible = true;
    const dialogRef = this.dialog.open(MccssDialogTitleComponent, {
      panelClass: 'sada-dialog-class'
    });

    dialogRef.componentInstance.dialogHeader = content.title;
    dialogRef.componentInstance.dialogBody = content.body;
    dialogRef.componentInstance.dialogButtonText = content.button;
    dialogRef.componentInstance.buttonAction = () => {
      dialogRef.close();
    }
    dialogRef.componentInstance.closeButtonAction = () => {
      dialogRef.close();
    }
    this.subscriptions$.push(dialogRef.afterClosed().subscribe(_ => this.dialogVisible = false));
  }

  private setUpInitialValues() {
    if (this.applicationAnswers) {
      for (const v of Object.values(this.formControlNames) as any) {
        if (this.applicationAnswers.jsonData[v.name] && this.form.get(v.name)) {
          this.form.get(v.name).setValue(this.applicationAnswers.jsonData[v.name], {emitEvent: true});
        }
      }
    }

    if (this.applicationAnswers.jsonData[this.formControlNames.trusteeNoLastName.name]?.length) {
      // tslint:disable-next-line:max-line-length
      this.noLastNameCheckBoxItems = [{value: 'yes', label: 'start-two.trusteeInfo.' + this.trusteeType + '.trusteeNoLastName', checked: true}]
      this.form.get(this.formControlNames.trusteeLastName.name).reset();
      this.form.get(this.formControlNames.trusteeLastName.name).disable();
    }
  }

  preventDefaultAction(event:any) {
    event.preventDefault();
  }

  get trusteeTypeOrganizationTrustee(){
    return TrusteeType.ORGANIZATION_TRUSTEE;
  }
}
