export const TrusteeFormControlConfigs= {
  individualTrustee:{
    trusteeFirstName:{
      name: 'trusteeFirstName',
    },
    trusteeLastName:{
      name: 'trusteeLastName',
    },
    trusteeNoLastName:{
      name: 'trusteeNoLastName'
    },
    trusteePhone:{
      name: 'trusteePhone',
    },
    trusteeEmail:{
      name: 'trusteeEmail',
    },
    trusteeContactLanguage:{
      name: 'trusteeContactLanguage',
    }
  },
  organizationTrustee:{
    organizationName:{
      name: 'trusteeOrganizationName',
    },
    trusteeFirstName:{
      name: 'trusteeContactFirstName',
    },
    trusteeLastName:{
      name: 'trusteeContactLastName',
    },
    trusteeNoLastName:{
      name: 'trusteeContactNoLastName'
    },
    trusteePhone:{
      name: 'trusteeOrgPhone',
    },
    trusteeEmail:{
      name: 'trusteeOrgEmail',
    },
    trusteeContactLanguage:{
      name: 'trusteeOrgContactLanguage',
    },
    trusteeIdentificationNumber:{
      name: 'trusteeIdentificationNumber',
    }
  }
}
