import {Injectable} from '@angular/core';
import {Question} from '../../common/utils/questions/question';
import {
  alphaNonNumericSplCharPattern,
  alphaSplCharPattern,
  amountMaxLength,
  inValidVehicleModelYearPattern,
  lettersSplCharPattern,
  numberPatternWithoutDecimal,
  positiveCurrencyPattern, validateAccountNumberLength,
  validateDescriptionExists,
  validateGreaterThanOtherValue,
  validateVehicleYear
} from '../../common/utils/validators/custom-validators';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';
import {validateInstitutionBranchNumber} from '../../common/utils/validators/bank-institution-branch-validator';
import {FormArray, FormGroup} from '@angular/forms';
import {BankingService} from '../../services/banking.service';
import {TranslateService} from '@ngx-translate/core';
import {bankNameChoices} from '../bank-details/bank-details.util';
import {otherAssetTypeChoices, otherAssetTypeDropdownOptions, vehicleMakeChoices} from './financial-assets.util';
import {UrlInfo} from '../../models/url-map';
import {ConfigService} from '../../services/config.service';

@Injectable()
export class FinancialAssetsQuestionService {
  constructor(private bankService: BankingService,
              private translator: TranslateService,
              private configService: ConfigService) {
  }

  getFinancialAssetsQuestions(): Question<string>[] {
    const questions: Question<string>[] = [

      {
        controlType: 'checkbox',
        key: 'FACash',
        checkboxBorders: true,
        backGroundClass: 'sada-checkbox-background-grey',
        needMarginTop: true,
        backGroundClassSelected: 'sada-checkbox-background-greyer',
        options: [{
          value: false,
          label: 'financial-assets.cash'
        }],
        disableOthersOnCondition:[
          {
            questionKey: 'FACashWhoOwnsThisAsset',
            questionControlType: 'checkbox',
            index: 0,
            value: [false],
          },
        ]
      },
      {
        controlType: 'checkbox',
        key: 'FACashWhoOwnsThisAsset',
        checkboxBorders: true,
        panelBackground: true,
        required: true,
        label: 'financial-assets.who.own.this.asset',
        indentedRow: true,
        options: [
          {
           value: false,
           label: 'financial-assets.applicant'
          }
        ],
        visibleOnConditions: [{
         questionKey: 'FACash',
         questionControlType: 'checkbox',
         index: 0,
         value: [true]
        }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.select.one.person'
          }
        ],
        disableOthersOnCondition:[]
      },
      {
        controlType: 'checkbox',
        key: 'FABankAccounts',
        checkboxBorders: true,
        backGroundClass: 'sada-checkbox-background-grey',
        backGroundClassSelected: 'sada-checkbox-background-greyer',
        needMarginTop: true,
        options: [{
          value: false,
          label: 'financial-assets.bank.accounts'
        }],
        disableOthersOnCondition:[
          {
            questionKey: 'FABankAccountsWhoOwnsThisAsset',
            questionControlType: 'checkbox',
            index: 0,
            value: [false],
          },
        ]
      },
      {
        controlType: 'checkbox',
        key: 'FABankAccountsWhoOwnsThisAsset',
        checkboxBorders: true,
        panelBackground: true,
        required: true,
        label: 'financial-assets.who.own.this.asset',
        indentedRow: true,
        options: [
          {
            value: false,
            label: 'financial-assets.applicant',
          }
        ],
        visibleOnConditions: [ {
          questionKey: 'FABankAccounts',
          questionControlType: 'checkbox',
          index: 0,
          value: [true]
        }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.select.one.person'
          }
        ],
        disableOthersOnCondition:[]
      },
      {
        controlType: 'checkbox',
        key: 'FAInvestments',
        checkboxBorders: true,
        backGroundClass: 'sada-checkbox-background-grey',
        backGroundClassSelected: 'sada-checkbox-background-greyer',
        needMarginTop: true,
        options: [{
          value: false,
          label: 'financial-assets.investments'
        }],
        disableOthersOnCondition:[
          {
            questionKey: 'FAInvestmentsWhoOwnsThisAsset',
            questionControlType: 'checkbox',
            index: 0,
            value: [false],
          },
        ]
      },
      {
        controlType: 'checkbox',
        key: 'FAInvestmentsWhoOwnsThisAsset',
        checkboxBorders: true,
        panelBackground: true,
        required: true,
        label: 'financial-assets.who.own.this.asset',
        indentedRow: true,
        options: [
          {
           value: false,
           label: 'financial-assets.applicant',
          }
        ],
        visibleOnConditions: [ {
          questionKey: 'FAInvestments',
          questionControlType: 'checkbox',
          index: 0,
          value: [true]
        }],
        validators: [
        {
         type: 'required',
         errorKey: 'error.select.one.person'
        }
       ],
       disableOthersOnCondition:[]
      },
      {
        controlType: 'checkbox',
        key: 'FAVehicles',
        checkboxBorders: true,
        backGroundClass: 'sada-checkbox-background-grey',
        backGroundClassSelected: 'sada-checkbox-background-greyer',
        options: [{
          value: false,
          label: 'financial-assets.vehicles'
        }],
        needMarginTop: true,
        disableOthersOnCondition:[
          {
            questionKey: 'FAVehiclesWhoOwnsThisAsset',
            questionControlType: 'checkbox',
            index: 0,
            value: [false],
          },
        ]
      },
      {
        controlType: 'checkbox',
        key: 'FAVehiclesWhoOwnsThisAsset',
        checkboxBorders: true,
        panelBackground: true,
        required: true,
        label: 'financial-assets.who.own.this.asset',
        indentedRow: true,
        options: [
          {
            value: false,
            label: 'financial-assets.applicant',
          }
        ],
        visibleOnConditions: [ {
          questionKey: 'FAVehicles',
          questionControlType: 'checkbox',
          index: 0,
          value: [true]
        }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.select.one.person'
          }
        ],
        disableOthersOnCondition:[]
      },
      {
        controlType: 'panel',
        key: 'FATrustFundPanel',
        needMarginTop: true,
        panels: [{
          name: 'FATrustFundPanel',
          text: 'financial-assets.trust.fund',
          questions: this.getTrustFundQuestions(),
          showAddButton: false
        }]
      },
      {
        controlType: 'checkbox',
        key: 'FAOwnedHome',
        checkboxBorders: true,
        backGroundClass: 'sada-checkbox-background-grey',
        backGroundClassSelected: 'sada-checkbox-background-greyer',
        disabled: true,
        visible: false,
        needMarginTop: true,
        options: [
          {
            value: true,
            label: 'financial-assets.owned.home',
          }
        ],
      },
      {
        controlType: 'checkbox',
        key: 'FAOtherAssets',
        checkboxBorders: true,
        backGroundClass: 'sada-checkbox-background-grey',
        backGroundClassSelected: 'sada-checkbox-background-greyer',
        needMarginTop: true,
        options: [{
          value: false,
          label: 'financial-assets.other.assets'
        }],
        disableOthersOnCondition:[
          {
            questionKey: 'FAOtherAssetsWhoOwnsThisAsset',
            questionControlType: 'checkbox',
            index: 0,
            value: [false],
          },
        ]
      },
      {
        controlType: 'checkbox',
        key: 'FAOtherAssetsWhoOwnsThisAsset',
        checkboxBorders: true,
        panelBackground: true,
        required: true,
        label: 'financial-assets.who.own.this.asset',
        indentedRow: true,
        options: [
          {
            value: false,
            label: 'financial-assets.applicant',
          }
        ],
        visibleOnConditions: [ {
          questionKey: 'FAOtherAssets',
          questionControlType: 'checkbox',
          index: 0,
          value: [true]
        }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.select.one.person'
          }
        ],
        disableOthersOnCondition:[]
      },
      {
        controlType: 'checkbox',
        key: 'FANoneOfAbove',
        checkboxBorders: true,
        backGroundClass: 'sada-checkbox-background-grey',
        backGroundClassSelected: 'sada-checkbox-background-greyer',
        resetWhenDisabled: true,
        needMarginTop: true,
        options: [{
          value: false,
          label: 'financial-assets.none.of.above'
        }],
        disabledOnAnyCondition:[
          {
            questionKey: 'FACash',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          },
          {
            questionKey: 'FABankAccounts',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          },
          {
            questionKey: 'FAInvestments',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          },
          {
            questionKey: 'FAVehicles',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          },
          {
            questionKey: 'FATrustFundPanel.panels.0.FATrustFundOPGT',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          },
          {
            questionKey: 'FATrustFundPanel.panels.0.FATrustFundOther',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          },
          {
            questionKey: 'FAOtherAssets',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          },
          {
            questionKey: 'FAOwnedHome',
            questionControlType: 'checkbox',
            index: 0,
            value: [true]
          },
        ]
      },
      {
        controlType: 'radio',
        key: 'FASoldOrGiveAwayAssets',
        required: true,
        label: 'financial-assets.sold.give.assets',
        context: 'financial-assets.sold.give.assets.context',
        needMarginTop: true,
        options: [{
          value: 'yes',
          label: 'choices.radioDefault.yes'
          },
          {
            value: 'no',
            label: 'choices.radioDefault.no'
          }],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      }
    ];

    return questions;
  }

  getTrustFundQuestions(): Question<string>[] {
    const questions: Question<string>[] = [
      {
        controlType: 'checkbox',
        key: 'FATrustFundOPGT',
        hintText: {
          text: 'financial-assets.trust.fund.OPGT.hintText',
          expandableContent: 'financial-assets.trust.fund.OPGT.hintContent',
          expandableContentParam: {link: [UrlInfo.publicGuardianTrusteeOffice, true]}
        },
        options: [{
          value: false,
          label: 'financial-assets.trust.fund.OPGT'
        }
        ],
        disableOthersOnCondition:[
          {
            questionKey: 'FATrustFundWhoOwnsThisAsset',
            questionControlType: 'checkbox',
            index: 0,
            value: [false],
          },
        ]
      },
      {
        controlType: 'checkbox',
        key: 'FATrustFundWhoOwnsThisAsset',
        required: true,
        label: 'financial-assets.who.own.this.asset',
        indentedRow: true,
        options: [{
          value: false,
          label: 'financial-assets.applicant',
         }
        ],
        visibleOnConditions: [{
          questionKey: 'FATrustFundOPGT',
          questionControlType: 'checkbox',
          index: 0,
          value: [true]
        },
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.select.one.person'
          }
        ],
        disableOthersOnCondition:[]
      },
      {
        controlType: 'divider',
        key: 'whatever',
        visibleOnConditions: [{
          questionKey: 'FATrustFundOPGT',
          questionControlType: 'checkbox',
          index: 0,
          value: [true]
        }]
      },
      {
        controlType: 'checkbox',
        key: 'FATrustFundOther',
        hintText: {text: 'financial-assets.trust.fund.other.hintText', expandableContent: 'financial-assets.trust.fund.other.hintContent'},
        options: [{
          value: false,
          label: 'financial-assets.trust.fund.other'
        }
        ],
        disableOthersOnCondition:[
          {
            questionKey: 'FATrustFundOtherWhoOwnsThisAsset',
            questionControlType: 'checkbox',
            index: 0,
            value: [false],
          },
        ]
      },
      {
        controlType: 'checkbox',
        key: 'FATrustFundOtherWhoOwnsThisAsset',
        required: true,
        label: 'financial-assets.who.own.this.asset',
        indentedRow: true,
        options: [{
           value: false,
           label: 'financial-assets.applicant',
          }
        ],
        visibleOnConditions:[{
          questionKey: 'FATrustFundOther',
          questionControlType: 'checkbox',
          index: 0,
          value: [true]
         },
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.select.one.person'
          }
        ],
        disableOthersOnCondition:[]
      },

    ];

    return questions;
  }

  getBankAccountsQuestions(labelParams: string[],context): Question<string>[] {

    const questions: Question<string>[] = [
      {
        controlType: 'textbox-auto-complete',
        key: 'bankName',
        required: true,
        capitalizeFirstLetter: true,
        hintText: {text: 'bank-details.bankName.hintText'},
        label: 'financial-assets.bank.name',
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'maxLength',
            maxLengthValue: 60,
            errorKey: 'bank-details.error.invalidBankNameLength'
          },
          {
            type: 'validatorFn',
            validatorFn: SadaCustomValidator.validateBankName,
            errorKey: 'bank-details.error.invalidBankName'
          }
        ],
        options: [...bankNameChoices()]
      },
      {
        controlType: 'radio',
        key: 'accountType',
        required: true,
        label:'bank-details.accountType',
        context,
        needMarginTop: true,
        labelParams,
        options: [
          {
            value: 'chequing',
            label: 'bank-details.account.choices.chequing'
          },
          {
            value: 'savings',
            label: 'bank-details.account.choices.savings'
          }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          }
        ]
      },
      {
        controlType: 'textbox',
        key: 'bankAccountValue',
        required: true,
        isDollarValue: true,
        needMarginTop: true,
        label: 'financial-assets.bank.account.value',
        characterWidth: 10,
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'min',
            minimumValue: 0,
            errorKey: 'error.invalid.min.amount'
          },
          {
            type: 'maxNumericLength',
            maxLengthValue: amountMaxLength,
            errorKey: 'error.invalid.max.amount'
          },
          {
            type: 'pattern',
            regex: positiveCurrencyPattern,
            errorKey: 'error.invalid.currency.amount'
          },
        ]
      },
      {
        controlType: 'textbox',
        key: 'applicantType',
        visible: false
      },
      {
        controlType: 'checkbox',
        key: 'useAccountForDBD',
        backGroundClass: 'sada-checkbox-background-grey',
        backGroundClassSelected: 'sada-checkbox-background-greyer',
        needMarginTop: true,
        options: [
          {
            value: false,
            label: 'financial-assets.use.account.for.dbd'
          }
        ],
        visibleOnConditions: [
          {
            questionKey: 'applicantType',
            value: ['primary']
          }
        ],
        disabledOnAnyCondition: [
          {
            customFn: disableUseAccountForDBDFn
          }
        ]
      },
      {
        controlType: 'innerHTML',
        key: 'chequeImageExample',
        needMarginTop: true,
        innerHTML:`<img class="cheque-image" id="cheque-image" alt="${this.translator.instant('bank-details.checkImage.alt')}" tabindex="0" src="assets/images/cheque.svg"/>`,
        visibleOnConditions: [
          {
            questionKey: 'useAccountForDBD',
            questionControlType: 'checkbox',
            value: [true],
            index: 0
          }
        ]
      },
      {
        controlType: 'textbox',
        key: 'branchNumber',
        required: true,
        label:'bank-details.branchNumber',
        hintText: {text: 'bank-details.branchNumber.hintText', expandableContent: 'bank-details.branchNumber.hintContent'},
        needMarginTop: true,
        characterWidth: 5,
        visibleOnConditions: [
          {
            questionKey: 'useAccountForDBD',
            questionControlType: 'checkbox',
            value: [true],
            index: 0
          }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'validatorFn',
            validatorFn: SadaCustomValidator.validateBranchNumber,
            errorKey: 'bank-details.error.invalidBranchNumber'
          },
          {
            type: 'asyncValidatorFn',
            asyncValidatorFn: (control) =>  validateInstitutionBranchNumber(control,'branchNumber', this.bankService),
            errorKey: 'bank-details.error.invalidDetails'
          }
        ]
      },
      {
        controlType: 'textbox',
        key: 'institutionNumber',
        required: true,
        label:'bank-details.institutionNumber',
        hintText: {text: 'bank-details.institutionNumber.hintText', expandableContent: 'bank-details.institutionNumber.hintContent'},
        needMarginTop: true,
        characterWidth: 3,
        visibleOnConditions: [
          {
            questionKey: 'useAccountForDBD',
            questionControlType: 'checkbox',
            value: [true],
            index: 0
          }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'validatorFn',
            validatorFn: SadaCustomValidator.validateInstitutionNumber,
            errorKey: 'bank-details.error.invalidInstitutionNumber'
          },
          {
            type: 'asyncValidatorFn',
            asyncValidatorFn:  (control) =>  validateInstitutionBranchNumber(control, 'institutionNumber', this.bankService),
            errorKey: 'bank-details.error.invalidDetails'
          }
        ]
      },
      {
        controlType: 'textbox',
        key: 'accountNumber',
        required: true,
        label:'bank-details.accountNumber',
        hintText: {text: 'bank-details.accountNumber.hintText', expandableContent: 'bank-details.accountNumber.hintContent'},
        needMarginTop: true,
        characterWidth: 10,
        visibleOnConditions: [
          {
            questionKey: 'useAccountForDBD',
            questionControlType: 'checkbox',
            value: [true],
            index: 0
          }
        ],
        validators: [
          {
            type: 'required',
            errorKey: 'error.required'
          },
          {
            type: 'crossField',
            validatorFn: validateAccountNumberLength(),
            isDynamicErrorKey: true
          }
        ]
      }
    ];

    return questions;
  }

}

export function getOtherAssetsQuestions(): Question<string>[] {

  const questions: Question<string>[] = [
    {
      controlType: 'dropdown',
      key: 'otherAssetType',
      required: true,
      label: 'financial-assets.other.asset.type',
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'crossField',
          validatorFn: validateDescriptionExists('otherAssetType', 'My asset type is not listed here'),
          errorKey: 'household-income.error.duplicateIncomeType'
        }
      ],
      options: [...otherAssetTypeChoices()]
    },
    {
      controlType: 'textbox',
      key: 'otherAssetDesc',
      required: true,
      needMarginTop: true,
      label: 'financial-assets.other.asset.desc',
      characterWidth: 20,
      capitalizeFirstLetter: true,
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'maxLength',
          maxLengthValue: 131,
          errorKey: 'error.invalid.description.max.length'
        },
        {
          type: 'pattern',
          regex: alphaNonNumericSplCharPattern,
          errorKey: 'error.invalid.description.asset'
        },
        {
          type: 'validatorFn',
          params: [otherAssetTypeDropdownOptions],
          validatorFn: SadaCustomValidator.validateSameValueAsDropdown,
          errorKey: 'financial-assets.error.sameAsDropdown'
        },
        {
          type: 'crossField',
          validatorFn: validateDescriptionExists('otherAssetDesc'),
          errorKey: 'financial-assets.error.duplicateAssetType'
        },
      ],
      visibleOnConditions: [{
        questionKey: 'otherAssetType',
        questionControlType: 'dropdown',
        value: ['My asset type is not listed here']
      }]
    },
    {
      controlType: 'textbox',
      key: 'otherAssetValue',
      required: true,
      needMarginTop: true,
      isDollarValue: true,
      label: 'financial-assets.other.asset.value',
      characterWidth: 10,
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'min',
          minimumValue: 1,
          errorKey: 'error.invalid.min.amount.detailed'
        },
        {
          type: 'maxNumericLength',
          maxLengthValue: amountMaxLength,
          errorKey: 'error.invalid.max.amount'
        },
        {
          type: 'pattern',
          regex: positiveCurrencyPattern,
          errorKey: 'error.invalid.currency.amount'
        },
      ]
    },
    {
      controlType: 'textbox',
      key: 'moneyInheritanceValue',
      required: true,
      needMarginTop: true,
      isDollarValue: true,
      label: 'financial-assets.other.asset.moneyInheritanceValue',
      hintText: {text: 'financial-assets.other.asset.moneyInheritanceValue.hintText'},
      characterWidth: 10,
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'min',
          minimumValue: 0,
          errorKey: 'error.invalid.min.amount.detailed'
        },
        {
          type: 'maxNumericLength',
          maxLengthValue: amountMaxLength,
          errorKey: 'error.invalid.max.amount'
        },
        {
          type: 'pattern',
          regex: positiveCurrencyPattern,
          errorKey: 'error.invalid.currency.amount'
        },
        {
          type: 'crossField',
          validatorFn: validateGreaterThanOtherValue('moneyInheritanceValue', 'otherAssetValue'),
          errorKey: 'financial-assets.error.inheritanceAmtIsNotLessThanAssetValue'
        },
      ],
      visibleOnConditions: [{
        questionKey: 'otherAssetType',
        questionControlType: 'dropdown',
        value: ['Money from an inheritance']
      }]
    }
  ];

  return questions;
}

export function getVehicleQuestions(labelParams: string[],context): Question<string>[] {
  const questions: Question<string>[] = [
    {
      controlType: 'textbox',
      key: 'vehicleYear',
      required: true,
      characterWidth: 4,
      label: 'financial-assets.vehicle.year',
      hintText: {text: 'financial-assets.vehicle.year.hintText', expandableContent: 'financial-assets.vehicle.year.hintContent'},
      inputClass: 'ontario-input ontario-input--4-char-width',
      validators: [
        {
          type: 'required',
          errorKey: 'error.invalid.sd.year.missing'
        },
        {
          type: 'minLength',
          minLengthValue: 4,
          errorKey: 'error.invalid.sd.date'
        },
        {
          type:'vehicleYearBefore1900Check',
          regex: inValidVehicleModelYearPattern,
          errorKey:'error.invalid.min.vehicle.year'
        },
        {
          type: 'validatorFn',
          validatorFn: validateVehicleYear,
          errorKey: 'error.invalid.vehicle.year'
        },
        {
          type:'pattern',
          regex: numberPatternWithoutDecimal,
          errorKey:'error.invalid.pattern.sd.date'
        }
      ]
    },
    {
      controlType: 'textbox-auto-complete',
      key: 'vehicleMake',
      required: true,
      needMarginTop: true,
      label: 'financial-assets.vehicle.make',
      hintText: {text: 'financial-assets.vehicle.make.hintText', expandableContent: 'financial-assets.vehicle.make.hintContent'},
      capitalizeFirstLetter: true,
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'maxLength',
          maxLengthValue: 40,
          errorKey: 'error.invalid.vehicle.make.length'
        },
        {
          type: 'pattern',
          regex: lettersSplCharPattern,
          errorKey: 'error.invalid.vehicle.make.desc'
        },
      ],
      options: [...vehicleMakeChoices()]
    },
    {
      controlType: 'textbox',
      key: 'vehicleModel',
      required: true,
      needMarginTop: true,
      label: 'financial-assets.vehicle.model',
      hintText: {text: 'financial-assets.vehicle.model.hintText', expandableContent: 'financial-assets.vehicle.model.hintContent'},
      capitalizeFirstLetter: true,
      characterWidth: 20,
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'maxLength',
          maxLengthValue: 40,
          errorKey: 'error.invalid.vehicle.model.length'
        },
        {
          type: 'pattern',
          regex: alphaSplCharPattern,
          errorKey: 'error.letters.number.only'
        },
      ]
    },
    {
      controlType: 'textbox',
      key: 'vehicleValue',
      required: true,
      needMarginTop: true,
      isDollarValue: true,
      label: 'financial-assets.vehicle.value',
      hintText: {text: 'financial-assets.vehicle.value.hintText', expandableContent: 'financial-assets.vehicle.value.hintContent'},
      characterWidth: 10,
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'min',
          minimumValue: 1,
          errorKey: 'error.invalid.min.amount.detailed'
        },
        {
          type: 'maxNumericLength',
          maxLengthValue: amountMaxLength,
          errorKey: 'error.invalid.max.amount'
        },
        {
          type: 'pattern',
          regex: positiveCurrencyPattern,
          errorKey: 'error.invalid.currency.amount'
        },
      ]
    },
    {
      controlType: 'radio',
      key: 'vehicleForWork',
      required: true,
      needMarginTop: true,
      label: 'financial-assets.vehicle.for.work',
      labelParams,
      context,
      options: [{
        value: 'yes',
        label: 'choices.radioDefault.yes'
      },
        {
          value: 'no',
          label: 'choices.radioDefault.no'
        }],
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
      ]
    }
  ];

  return questions;
}

export function getInvestmentValueQuestion(): Question<string>[] {

  const questions: Question<string>[] = [

    {
      controlType: 'textbox',
      key: 'investmentTotalValue',
      required: true,
      isDollarValue: true,
      label: 'financial-assets.investment.value',
      characterWidth: 10,
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'min',
          minimumValue: 0,
          errorKey: 'error.invalid.min.amount'
        },
        {
          type: 'maxNumericLength',
          maxLengthValue: amountMaxLength,
          errorKey: 'error.invalid.max.amount'
        },
        {
          type: 'pattern',
          regex: positiveCurrencyPattern,
          errorKey: 'error.invalid.currency.amount'
        },
      ]
    }
  ];

  return questions;
}

export function getTrustFundOpgtQuestions(): Question<string>[] {

  const questions: Question<string>[] = [

    {
      controlType: 'textbox',
      key: 'trustFundOpgtTotalValue',
      required: true,
      isDollarValue: true,
      label: 'financial-assets.investment.value',
      characterWidth: 10,
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'min',
          minimumValue: 1,
          errorKey: 'error.invalid.min.amount.detailed'
        },
        {
          type: 'maxNumericLength',
          maxLengthValue: amountMaxLength,
          errorKey: 'error.invalid.max.amount'
        },
        {
          type: 'pattern',
          regex: positiveCurrencyPattern,
          errorKey: 'error.invalid.currency.amount'
        },
        {
          type: 'validatorFn',
          validatorFn: SadaCustomValidator.validateNonZeroCurrency,
          errorKey: 'error.invalid.zero.amount'
        }
      ]
    },
    {
      controlType: 'textbox',
      key: 'trustFundOpgtAccessibleValue',
      required: true,
      needMarginTop: true,
      isDollarValue: true,
      label: 'financial-assets.trust.fund.accessible',
      characterWidth: 10,
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'min',
          minimumValue: 0,
          errorKey: 'error.invalid.min.amount.detailed'
        },
        {
          type: 'maxNumericLength',
          maxLengthValue: amountMaxLength,
          errorKey: 'error.invalid.max.amount'
        },
        {
          type: 'pattern',
          regex: positiveCurrencyPattern,
          errorKey: 'error.invalid.currency.amount'
        },
        {
          type: 'crossField',
          validatorFn: validateGreaterThanOtherValue('trustFundOpgtAccessibleValue', 'trustFundOpgtTotalValue'),
          errorKey: 'financial-assets.error.assetValueIsNotLessThanAccessibleOPGT'
        },
      ],
    }
  ];

  return questions;
}

export function getTrustFundOtherQuestion(): Question<string>[] {

  const questions: Question<string>[] = [

    {
      controlType: 'textbox',
      key: 'trustFundOtherTotalValue',
      required: true,
      characterWidth: 10,
      isDollarValue: true,
      label: 'financial-assets.investment.value',
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'min',
          minimumValue: 1,
          errorKey: 'error.invalid.min.amount.detailed'
        },
        {
          type: 'maxNumericLength',
          maxLengthValue: amountMaxLength,
          errorKey: 'error.invalid.max.amount'
        },
        {
          type: 'pattern',
          regex: positiveCurrencyPattern,
          errorKey: 'error.invalid.currency.amount'
        },
        {
          type: 'validatorFn',
          validatorFn: SadaCustomValidator.validateNonZeroCurrency,
          errorKey: 'error.invalid.zero.amount'
        }
      ]
    },
    {
      controlType: 'textbox',
      key: 'trustFundPrivateAccessibleValue',
      required: true,
      needMarginTop: true,
      isDollarValue: true,
      label: 'financial-assets.trust.fund.other.amount',
      characterWidth: 10,
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'min',
          minimumValue: 0,
          errorKey: 'error.invalid.min.amount.detailed'
        },
        {
          type: 'maxNumericLength',
          maxLengthValue: amountMaxLength,
          errorKey: 'error.invalid.max.amount'
        },
        {
          type: 'pattern',
          regex: positiveCurrencyPattern,
          errorKey: 'error.invalid.currency.amount'
        },
        {
          type: 'crossField',
          validatorFn: validateGreaterThanOtherValue('trustFundPrivateAccessibleValue', 'trustFundOtherTotalValue'),
          errorKey: 'financial-assets.error.otherAmtIsNotLessThanTotalValue'
        },
      ]
    }
  ];

  return questions;
}

export function getAmountQuestion(): Question<string>[] {

  const questions: Question<string>[] = [

    {
      controlType: 'textbox',
      key: 'financialAssetCashAmount',
      required: true,
      label: 'financial-assets.amount',
      isDollarValue: true,
      characterWidth: 10,
      validators: [
        {
          type: 'required',
          errorKey: 'error.required'
        },
        {
          type: 'min',
          minimumValue: 0,
          errorKey: 'error.invalid.min.amount'
        },
        {
          type: 'maxNumericLength',
          maxLengthValue: amountMaxLength,
          errorKey: 'error.invalid.max.amount'
        },
        {
          type: 'pattern',
          regex: positiveCurrencyPattern,
          errorKey: 'error.invalid.currency.amount'
        },
      ]
    }
  ];

  return questions;
}

/**
 * disable useAccountForDBD checkbox for the following condition:
 * The useAccountForDBD checkbox has been selected in one of the other panels for the primary applicant.
 */
export const disableUseAccountForDBDFn = (form: FormGroup, panelIndex: number) => {
  let disable = false;

  const fa = form.get('panels') as FormArray;
  if ( fa.length > 1 ) {
    disable = fa.controls.filter((formGroup, index) => {
      return index !== panelIndex && formGroup.get('useAccountForDBD')?.value[0] === true
    }).length > 0
  }

  return disable;
}
