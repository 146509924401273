import {AfterViewChecked, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
  ApplyFor, Languages, NeedOrganizationTrustee,
  NeedTrustee,
  ReceivedSocialAssistanceProgram,
  StatusInCanada,
  StatusNotEligibleForSupport
} from '../../common/utils/questions/question-choices';
import {PageBaseComponent} from '../PageBaseComponent';
import {IntakeService} from '../../services/intake.service';
import {TranslateService} from '@ngx-translate/core';
import {BeforeYouStartTwoQuestionService, RelationshipsToApplicantChoices, StartTwoPopup} from './before-you-start-two-question.service';
import {QuestionControlService} from '../../common/utils/questions/question-control.service';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';
import {validValueValidator} from '../../common/utils/validators/valid-value-validator';
import {invalidValueValidator} from '../../common/utils/validators/invalid-value-validator';
import {ExternalRouter} from '../../external.router';
import {CaseWorkerAuthorizeService} from '../../services/caseWorkerAuthorizeService';
import {distinctUntilChanged, filter, startWith, take, takeUntil} from 'rxjs/operators';
import moment from 'moment';
import {ConfigService} from '../../services/config.service';
import {StatusInCanadaComponentUtil} from '../../common/status-in-canada-questions/util/status-in-canada-component.util';
import {InvisibleReCaptchaComponent} from 'ngx-captcha';
import {GoogleReCaptchaV2Service} from '../../services/google-recaptcha.service';
import {EmailService} from '../../services/email.service';
import {PageInfo} from '../../models/page-map';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {UrlInfo} from '../../models/url-map';
import {
  firstNationsNameChoices,
  firstNationsODSPRedirectsNames,
  syncDataForAdditionalInfo,
  syncDisabilityData,
  syncReceivedSocialAssistanceData
} from './before-you-start-two.util';
import {ProgramType} from '../../models/program-type';
import {DataType} from '../../common/ui/text-question/util/text-question-component.util';
import {TrusteeType} from '../../models/trustee-type';
import {TrusteeInfoComponentUtil} from '../../common/trustee-info-questions/util/trustee-info-component.util';
import {RelationshipType} from '../../models/relationship-type';

@Component({
  selector: 'sd-before-you-start-two',
  templateUrl: './before-you-start-two.component.html',
  styleUrls: ['./before-you-start-two.component.scss']
})
export class BeforeYouStartTwoComponent extends PageBaseComponent implements OnInit, OnDestroy, AfterViewChecked {
  isAuthorizedUser = false;
  isLoggedInPS = false;
  showRecaptchaV2Error = false;
  authenticatedMyBApplicant = false;
  recaptcha: any = null;
  firstNationsDisabilityValidationFns;
  readonly dataType = DataType;
  languages = Languages;
  showError = false;
  firstNationError: string|undefined;
  firstNationErrorParam: any;
  receiveMoneyError: string|undefined;
  receivingMoneyReasonError: string|undefined;
  invalidStatusInCanadaError: string|undefined;

  statusInCanada = StatusInCanada;
  arrivalDateToCanada: any;
  applyingForChoices = ApplyFor;
  isApplyingForSomeoneElse = false;
  isRelationShipToApplicantSelected = false;
  isRelatedToLocalOfficeAssistance = false;
  isApplyingForMyself = false;
  isReleasedFromInstitution = false;
  isLivingOnFirstNationsLand = false;
  hasDisability = false;
  trusteeType;
  trusteeIdentified = false;
  isInitializing = true;
  dateOfReleaseFromInstitutionValidationFns;
  memberIdValidationFns;
  arrivalDateValidationFn;
  firstNationsNameValidationFns;
  receivedSocialAssistanceProgramValidationFns;
  dateOfAssistanceValidationFns;
  emergencyAuthorizationValidationFns;
  previousDateOfRelease: string;
  relationshipsToApplicantChoices = RelationshipsToApplicantChoices
  needTrustee = NeedTrustee;
  receivedSocialAssistanceProgramRadio = ReceivedSocialAssistanceProgram
  emergencyAssistanceLinkParam: any;
  emergencyAuthorizationLinkParam: any;
  dateOfSocialAssistanceInPastParam: any;
  clearCacheLinkParam: any;
  legalStatusHintTextParam: any;
  resettlementAssistanceProgramLinkParam: any;
  officeLocationLinkParam: any;
  firstNationsNameLinkParam: any;
  notEligibleLinkParam: any;
  notEligibleBodyLinkParam: any;

  /**
   * Reference to recaptcha v2 element
   */
  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;

  constructor(private fb: FormBuilder, private router: Router,
              public route: ActivatedRoute,
              public intake: IntakeService, public translator: TranslateService,
              private qs: BeforeYouStartTwoQuestionService,
              private qcs: QuestionControlService,
              public ngZone: NgZone,
              private readonly changeDetectorRef: ChangeDetectorRef,
              public idle: Idle,
              public dialog: MatDialog,
              public externalRouter: ExternalRouter,
              private emailService: EmailService,
              private authorizeService: CaseWorkerAuthorizeService,
              protected configService: ConfigService,
              public recaptchaV2Service: GoogleReCaptchaV2Service,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.bya;
  }
  get choices() {
    return firstNationsNameChoices();
  }

  get hasReceivedSocialAssistanceInPast() {
    return this.form.get('receivedSocialAssistanceInPast').value === 'yes';
  }

  get receivedSocialAssistanceInPast() {
    return this.form.get('receivedSocialAssistanceInPast')?.value;
  }

  get dateOfSocialAssistanceInPast() {
    return this.form.get('dateOfSocialAssistanceInPast')?.value;
  }

  get memberID() {
    return this.form.get('memberID')?.value;
  }

  get receivedSocialAssistanceProgram() {
    return this.form.get('receivedSocialAssistanceProgram')?.value;
  }

  get moneyForImmediateNeed() {
    return this.form.get('moneyForImmediateNeed')?.value;
  }

  get withDisability() {
    return this.form.get('withDisability')?.value;
  }

  get isStatusInCanadaGovAssistedRefugee() {
    return StatusInCanadaComponentUtil.isGovernmentAssistedRefugee(this.form.get('statusInCanada').value);
  }

  /**
   * Returns true if the backend service is having it enabled.
   */
  get recaptchaEnabled() {
    return this.recaptchaV2Service.enableRecaptcha;
  }

  get recaptchaDisplayed() {
    return this.recaptchaEnabled
           && !this.isAuthorizedUser
           && !this.isLoggedInPS
  }

  ngOnInit(): void {
    this.isAuthorizedUser = this.authorizeService.isAuthorized(); // Caseworker flow
    this.isLoggedInPS = this.authService.isAuthorizedToSave();    // Logged in through public secure / myb flow
    this.authenticatedMyBApplicant = this.isLoggedInPS && this.authService.isMyBApplicant();

    this.setupForm();
    this.setupLinks();

    // initialize form with application data
    this.initializeForm();

    this.setupValidators();

    this.filterApplicantRelationshipForPublicFlow();

    this.form?.controls.statusInCanada.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.onStatusInCanadaChange(value)
    });

    this.form?.controls.receivedSocialAssistanceInPast.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.onReceivedSocialAssistanceInPastChange(value)
    });

    this.form?.controls.receivedSocialAssistanceProgram.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.valueChangeSubjects$))
      .subscribe(value => {
        this.onReceivedSocialAssistanceProgramChange(value)
      });

    this.form?.controls.dateOfSocialAssistanceInPast.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.valueChangeSubjects$))
      .subscribe(value => {
        this.onDateOfSocialAssistanceInPastChange(value)
    });

    this.form?.controls.moneyForImmediateNeed.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.valueChangeSubjects$))
      .subscribe(value => {
        this.onMoneyForImmNeedChange(value)
      });

    this.form?.controls.livingOnFirstNationsReserveLand.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.onLivingOnFirstNationsReserveLandChange(value)
    });

    this.form?.controls.withDisability.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.onPersonWithDisabilityChange(value)
    });

    this.form?.controls.firstNationsName.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.valueChangeSubjects$))
      .subscribe(value => {
        this.onFirstNationsNameChange(value)
    });

    this.form?.controls.receivingMoneyFromExistingProgram.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.onReceivingMoneyFromExistingProgramChange(value)
    });

    this.form?.controls.applyingForYourselfOrSomeoneElse.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.onApplyingForYourselfOrSomeoneElseChange(value)
    });

    this.form?.controls.releasedFromInstitution.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.onReleasedFromInstitutionChange(value)
    });

    this.form?.controls.receivingMoneyForReason.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.onReceivingMoneyForReasonChange(value);
    });

    this.form?.controls.withDisability.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.onDisabilityChange(value);
    });

    this.form?.controls.applyingForSomeoneElseRelationship.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      this.onRelationshipChange(value);
    });

    this.form?.controls.applyingForSomeoneElseRelationshipNeedTrustee.valueChanges.pipe(takeUntil(this.valueChangeSubjects$))
      .subscribe(value => {
        this.onNeedTrusteeChange(value);
    });

    if (this.recaptchaDisplayed) {
      this.translator.onLangChange.subscribe(() => {
        if (this.recaptchaV2Service.captchaIsLoaded) {
          this.recaptchaV2Service.changeLanguage(this.captchaElem);
        }
      })
    }

    // Disable applyingForYourselfOrSomeoneElse when returning as authenticated user
    if (this.isLoggedInPS && this.isApplyingForMyself) {
      this.form.get('applyingForYourselfOrSomeoneElse')?.disable();
    }

    this.translator.onLangChange.subscribe((lang) => {
      this.setupLinks();
    })
  }

  onSubmit(toContinue): void {
    this.showPsRedirectError = false;  // Reset the error flag.
    this.showRequiredInfoBanner = false;
    if (this.isLivingOnFirstNationsLand && this.hasDisability) {
      const theFirstNationsNameVal = this.form.get('firstNationsName').value;
      const firstNationNameFound = this.choices.some(item => item.label === theFirstNationsNameVal);
      if (!firstNationNameFound) {
        this.form.get('firstNationsName').setErrors({incorrect: true});
        this.form.get('firstNationsName').setErrors({required: true});
      }
    }
    this.onReceivingMoneyForReasonChange(this.form.get('receivingMoneyForReason').getRawValue(), false);
    // Bugfix #67566
    // This code block can be removed in 24.6 when there is no application that has applyingForSomeoneElseRelationshipNeedTrustee 'yes'
    if(this.form.get('applyingForSomeoneElseRelationshipNeedTrustee').getRawValue() === 'yes'){
      this.form.get('applyingForSomeoneElseRelationshipNeedTrustee').setErrors({required: true});
    }
    if (this.form.valid) {
      this.saveAndContinueOrExit(toContinue);
    } else if (this.form.invalid) {
      this.showError = true
      this.scrollToInvalidFormControl(toContinue);
    } else {
      // Below handling is required to handle ASYNC validators. Submission should be delayed until all async validators are executed.
      // Until async validators are done, status would be 'PENDING'
      this.subscriptions$.push(
        this.form.statusChanges.pipe(
          startWith(this.form.status),
          filter(status => status !== 'PENDING'),
          take(1)).subscribe(status => {
          if (status === 'VALID') {
            this.saveAndContinueOrExit(toContinue);
          } else {
            this.showError = true
            this.scrollToInvalidFormControl(toContinue);
          }
      }));
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  // define input fields on form
  setupForm(): void {
    this.form  = this.fb.group({
      receivedSocialAssistanceInPast: ['', [Validators.required]],
      receivedSocialAssistanceProgram: [],
      dateOfSocialAssistanceInPast: [],
      memberID: [],
      statusInCanada:['', [Validators.required, invalidValueValidator(StatusNotEligibleForSupport)]],
      arrivalDateToCanada: [],
      releasedFromInstitution: [],
      dateOfReleaseFromInstitution:[],
      livingOnFirstNationsReserveLand: ['', [Validators.required]],
      withDisability: [],
      firstNationsName: [],
      receivingMoneyFromExistingProgram: ['', [Validators.required, validValueValidator('no')]],
      moneyForImmediateNeed: ['', [Validators.required]],
      receivingMoneyForReason: ['', [Validators.required, validValueValidator('no')]],
      applyingForYourselfOrSomeoneElse: [],
      applyingForSomeoneElseRelationship: [''],
      applyingForSomeoneElseRelationshipNeedTrustee: [],
      recaptcha: ['']
    });

    this.recaptchaV2Service.setChangeDetectorRef(this.changeDetectorRef);
  }

  private setupLinks(): void {
    this.emergencyAssistanceLinkParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.emergencyAssistance)
    };
    this.emergencyAuthorizationLinkParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.emergencyAssistance)
    };
    this.dateOfSocialAssistanceInPastParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.officeLocation)
    }
    this.clearCacheLinkParam = {
      link1: this.configService.getUrl(this.translator.currentLang, UrlInfo.chromeClearCache),
      link2: this.configService.getUrl(this.translator.currentLang, UrlInfo.safariClearCache),
      link3: this.configService.getUrl(this.translator.currentLang, UrlInfo.edgeClearCache),
      link4: this.configService.getUrl(this.translator.currentLang, UrlInfo.fireboxClearCache)
    };
    this.legalStatusHintTextParam = {
      link1: this.configService.getUrl(this.translator.currentLang, UrlInfo.certificateOfIndianStatus),
      link2: this.configService.getUrl(this.translator.currentLang, UrlInfo.immigrationStatusDocument),
      link3: this.configService.getUrl(this.translator.currentLang, UrlInfo.officeLocation)
    };
    this.resettlementAssistanceProgramLinkParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.resettlementAssistanceProgram)
    };
    this.officeLocationLinkParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.officeLocation)
    };
    this.firstNationsNameLinkParam = {
      link: this.configService.getUrl(this.translator.currentLang, UrlInfo.officeLocation)
    }
    this.notEligibleLinkParam = {
      link1: this.configService.getUrl(this.translator.currentLang, UrlInfo.myBenefits),
      link2: this.configService.getUrl(this.translator.currentLang, UrlInfo.officeLocation)
    }
    this.notEligibleBodyLinkParam = {
      link1: this.configService.getUrl(this.translator.currentLang, UrlInfo.myBenefits),
      link2: this.configService.getUrl(this.translator.currentLang, UrlInfo.officeLocation)
    }
    this.firstNationErrorParam = this.officeLocationLinkParam;
  }

  setupValidators(): void {
    this.dateOfReleaseFromInstitutionValidationFns = [
      {
        validationFunction: (v: []) => {
          return SadaCustomValidator.isValidDate(v) && !SadaCustomValidator.isInPastExcludeToday(v);
        }, errorKey: 'error.invalid.date'
      },
      {
        validationFunction: (v: []) => {
          return SadaCustomValidator.isInPastExcludeToday(v) || SadaCustomValidator.isDateWithinDaysIncludeToday([...v, '10']);
        }, errorKey: 'start-two.dialog.dateOfRelease.body'
      }
    ];
    this.memberIdValidationFns = [{validationFunction: SadaCustomValidator.validateMemberID, errorKey: 'error.invalid.memberId'}];

    this.arrivalDateValidationFn = [
      {
        validationFunction: (v: string[]) => {
          if (v && v.length > 0) {
            const trimmedDate = v[0].replace(/\s/g, '');
            if (!this.hasDisability) {
              return !SadaCustomValidator.isDateWithinPastMonths(moment(trimmedDate, 'YYYY/MM'), 0, 12)
            } else {
              return true;
            }
          }
        },
        errorKey: 'error.invalid.arrival.status.in.canada',
        errorParam: {link: 'links.resettlement.assistance.program'},
        params: [],
        isLinkParamNeedTranslate: true
      },
      {
        validationFunction: (v: string[]) => {
          if (v && v.length > 0) {
            const trimmedDate = v[0].replace(/\s/g, '');
            if (this.hasDisability) {
              return !moment(trimmedDate).isBefore('2000/01/01');
            } else {
              return true;
            }
          }
        }, errorKey: 'error.invalid.date'
      },
      {
        validationFunction: (v: []) => {
          return SadaCustomValidator.validateDateWithMinAndFuture([...v, '1900/01'])
        }, errorKey: 'error.invalid.date'
      },
    ];

    this.firstNationsNameValidationFns = [
      {
        validationFunction: (v: string[]) => v && v.length > 0 ? !firstNationsODSPRedirectsNames.includes(v[0]) : true,
        errorKey: 'error.firstNationsName', errorParam: this.firstNationsNameLinkParam
      }
    ]

    this.receivedSocialAssistanceProgramValidationFns = [
      {
        validationFunction: (v: string[]) => {
          const moneyForImmediateNeed = this.moneyForImmediateNeed;
          return !(moneyForImmediateNeed === 'no' && v.length >0 && v[0] === ProgramType.ODS);
        }, errorKey: 'error.reopenPastODSApplication'
      }
    ];

    this.dateOfAssistanceValidationFns = [
      {
        validationFunction: (v: string[]) => {
          const receivedSocialAssistanceProgram = this.form.get('receivedSocialAssistanceProgram')?.value;
          if (v && v.length > 0) {
            const trimmedDate = v[0].replace(/\s/g, '');
            return !SadaCustomValidator.isDateWithinPastMonthsOnCondition(moment(trimmedDate, 'YYYY/MM'), 0, 3,
              receivedSocialAssistanceProgram === ProgramType.ONW)
          }
          return true;
        }, errorKey: 'error.reopenPastApplication', errorParam: this.dateOfSocialAssistanceInPastParam
      },
      {
        validationFunction: (v: []) => {
          return SadaCustomValidator.validateDateWithMinAndFuture([...v, '1900/01'])
        }, errorKey: 'error.invalid.date'
      }
    ]

    this.firstNationsDisabilityValidationFns = [
      {
        validationFunction: (value) => {
          const livingOnFirstNationsReserveLandValue = this.form.get('livingOnFirstNationsReserveLand')?.value;
          const withDisabilityValue = this.form.get('withDisability')?.value;
          return SadaCustomValidator.validateFirstNationBasedOnDisabilityBya(livingOnFirstNationsReserveLandValue, withDisabilityValue);
        },
        errorKey: 'start-two.livingOnFirstNationsReserveLand.error'
      }
    ];
  }

  // populate dynamic panel for Applying for someone else
  postInitializeForm() {

    this.prePopulateForIBauUser();
    this.isApplyingForSomeoneElse = this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse === 'APPLICATION_OTHER';
    this.isRelationShipToApplicantSelected = !!this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse;
    this.isApplyingForMyself = this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse === 'APPLICATION_SELF';
    // tslint:disable-next-line:max-line-length
    this.isRelatedToLocalOfficeAssistance = this.applicationAnswers.jsonData.applyingForSomeoneElseRelationship === RelationshipType.LOCAL_OFFICE_ASSISTANCE;
    this.trusteeIdentified = this.applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee === 'trusteeIdentified';

    this.isReleasedFromInstitution = this.applicationAnswers.jsonData.releasedFromInstitution === 'yes'

    this.isLivingOnFirstNationsLand = this.applicationAnswers.jsonData.livingOnFirstNationsReserveLand === 'yes'

    this.hasDisability = this.applicationAnswers.jsonData.withDisability === 'yes'

    this.isInitializing = false;

    if (this.authorizeService.isIBauUser()) {
      // disable controls for IBAU users
      this.form.get('applyingForYourselfOrSomeoneElse').disable();
      this.form.get('applyingForSomeoneElseRelationship').disable();
    }
  }

  // default applyingForYourselfOrSomeoneElse for IBAU users on secured site.
  prePopulateForIBauUser() {

    if (this.authorizeService.isIBauUser()) {

      if (this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse === undefined) {

        this.applicationAnswers.jsonData.applyingForYourselfOrSomeoneElse = 'APPLICATION_OTHER';
        this.applicationAnswers.jsonData.applyingForSomeoneElseRelationship = RelationshipType.APPLICATION_SUPPORT;
        this.form.get('applyingForYourselfOrSomeoneElse').setValue('APPLICATION_OTHER') ;
        this.form.get('applyingForSomeoneElseRelationship').setValue(RelationshipType.APPLICATION_SUPPORT) ;

      }
    }

  }

  saveAndContinueOrExit(toContinue): void {

    if (!this.recaptchaDisplayed) {
      // reCAPTCHA skipped (disabled or authenticated user)
      this.removeDynamicQuestions();
      this.subscriptions$.push(this.saveForm(toContinue)?.pipe(distinctUntilChanged()).subscribe(() => {
        if (toContinue) {
          this.intake.setIsApplyingForSomeoneElse(this.isApplyingForSomeoneElse);
          this.intake.setIsRelatedToLocalOfficeAssistance(this.isRelatedToLocalOfficeAssistance);
            this.router.navigate(['/', 'intake', PageInfo.personalInfo]);
        } else {
          this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
        }
      }));
    } else {

      // Execute invisible recaptcha v2
      if (this.recaptchaV2Service.captchaIsLoaded) {
        if (this.captchaElem == null) {
          console.log('null captcha elem');
        } else {
          // console.log('Using the following reCAPTCHA v2 site key: '+this.recaptchaV2Service.siteKey)
          // console.log('Executing invisible reCAPTCHA v2');
          this.captchaElem.execute();
        }
      } else {
        console.log('reCAPTCHA v2 has not loaded, try again.');
      }
    }
  }

  handleRecaptchaV2Success($event) {
    this.recaptchaV2Service.handleSuccess($event).subscribe(resp => {
      if (this.recaptchaV2Service.captchaSuccess && !this.recaptchaV2Service.reCaptchaVerifyCallFailure) {
        console.log('reCAPTCHA success on frontend');
        // save/continue
        this.removeDynamicQuestions();
        this.saveForm()?.pipe(distinctUntilChanged()).subscribe(() => {
          this.intake.setIsApplyingForSomeoneElse(this.isApplyingForSomeoneElse);
          this.intake.setIsRelatedToLocalOfficeAssistance(this.isRelatedToLocalOfficeAssistance);
          this.router.navigate(['/', 'intake', PageInfo.personalInfo])
        });
      }
      else {
        console.log('reCAPTCHA failed on frontend');
        this.showRecaptchaV2Error = true;
      }
    });
  }

  preSaveApplication() {
    // set case worker data when logged in on secured site
    if (this.authorizeService.isAuthorized()) {
      this.applicationAnswers.jsonData.caseWorker = [this.authorizeService.getCaseWorker()];
    }

    if (this.isLivingOnFirstNationsLand && this.hasDisability) {
      const currentChoice = this.choices.find(({label}) => label === this.applicationAnswers.jsonData.firstNationsName);
      this.applicationAnswers.jsonData.firstNationsNameCode = currentChoice.value;
    }

    if (!(this.isLivingOnFirstNationsLand && this.hasDisability)) {
      delete this.applicationAnswers.jsonData.firstNationsName;
      delete this.applicationAnswers.jsonData.firstNationsNameCode;
    }

    // delete the base64 encoded value for the user's response.
    delete this.applicationAnswers.jsonData?.recaptcha;

    // Sync data from BYA with additional/family info page data if available
    syncDisabilityData(this.applicationAnswers, this.withDisability);
    syncDataForAdditionalInfo(this.applicationAnswers, this.moneyForImmediateNeed, 'moneyForImmediateNeed',
      'moneyForImmediateNeedInFamily', 'moneyForImmediateNeedInFamilyCheckbox');
    syncReceivedSocialAssistanceData(this.applicationAnswers, this.receivedSocialAssistanceInPast,
      this.dateOfSocialAssistanceInPast, this.memberID, this.receivedSocialAssistanceProgram);
  }

  onStatusInCanadaChange(value: any) {
    if (StatusNotEligibleForSupport.includes(value)) {
      this.invalidStatusInCanadaError = 'error.invalid.status.in.canada';
    } else {
      this.invalidStatusInCanadaError = '';
    }
    if (!StatusInCanadaComponentUtil.isGovernmentAssistedRefugee(value)) {
      if (this.form?.get('arrivalDateToCanada')) this.form.removeControl('arrivalDateToCanada')
    }
  }

  onReceivingMoneyForReasonChange(value: string, openDialog: boolean = true) {
    if (value === 'yes') {
      if (openDialog) {
        this.openDialog(StartTwoPopup.emergencyAssistance, false, () => {
          window.location.href = this.emergencyAssistanceLinkParam.link;
        });
      }
      this.receivingMoneyReasonError='start-two.receivingMoneyForReason.error'
    } else {
      this.receivingMoneyReasonError = undefined;
    }
  }

  onDisabilityChange(value: any){
    this.form.get('arrivalDateToCanada')?.updateValueAndValidity()
  }

  private removeAndDisableTrusteeFields(): void {
    TrusteeInfoComponentUtil.removeTrusteeInfoFormControls(this.form, this.fb, this.applicationAnswers, TrusteeType.ORGANIZATION_TRUSTEE)
    TrusteeInfoComponentUtil.removeTrusteeInfoFormControls(this.form, this.fb, this.applicationAnswers, TrusteeType.INDIVIDUAL_TRUSTEE)
  }

  private removeTrusteeAddressData(applicationData){
    delete applicationData.trusteeDeliveryType;
    delete applicationData.trusteeApartmentNumber;
    delete applicationData.trusteeStreetNumber;
    delete applicationData.trusteeStreetNumberSuffix;
    delete applicationData.trusteeStreetName;
    delete applicationData.trusteeStreetType;
    delete applicationData.trusteeDirection;
    delete applicationData.trusteeGeneralDelivery;
    delete applicationData.trusteePoBox;
    delete applicationData.trusteeStation;
    delete applicationData.trusteeRuralRoute;
    delete applicationData.trusteeCityOrTown;
    delete applicationData.trusteeProvince;
    delete applicationData.trusteePostalCode;
    delete applicationData.trusteeIsApartment;
    delete applicationData.trusteeIsManualEntry;
  }

  onApplyingForYourselfOrSomeoneElseChange(value: any) {
    if (value === 'APPLICATION_OTHER') {
      if (!this.authorizeService.isIBauUser()) {
        this.form.get('applyingForSomeoneElseRelationship').enable()
      }
      this.form.get('applyingForSomeoneElseRelationship').addValidators(Validators.required)
      this.form.get('applyingForSomeoneElseRelationship').updateValueAndValidity()
      this.form.get('applyingForSomeoneElseRelationshipNeedTrustee').enable()
      this.form.get('applyingForSomeoneElseRelationshipNeedTrustee').addValidators(Validators.required)
      this.form.get('applyingForSomeoneElseRelationshipNeedTrustee').updateValueAndValidity()
      this.isApplyingForSomeoneElse = true
      this.isApplyingForMyself = false;
    }
    if (value === 'APPLICATION_SELF') {
      this.isApplyingForMyself = true;
      this.form.get('applyingForSomeoneElseRelationship').setValue('')
      this.form.get('applyingForSomeoneElseRelationship').disable()
      this.form.get('applyingForSomeoneElseRelationshipNeedTrustee').disable()
      this.removeTrusteeAddressData(this.applicationAnswers.jsonData);
      this.removeAndDisableTrusteeFields();
      this.isApplyingForSomeoneElse = false;
      this.isRelatedToLocalOfficeAssistance = false;
      this.trusteeIdentified = false;
    }
  }

  onReceivedSocialAssistanceInPastChange(value: any) {
    if (value === 'no') {
      this.form.controls.receivedSocialAssistanceProgram?.disable();
      this.form.controls.dateOfSocialAssistanceInPast?.disable();
      this.form.controls.memberID?.disable();
      this.form.get('receivedSocialAssistanceProgram').reset()
      this.form.get('dateOfSocialAssistanceInPast').reset()
      this.form.get('memberID').reset()
    } else {
      this.form.get('receivedSocialAssistanceProgram').enable();
      this.form.get('receivedSocialAssistanceProgram').addValidators(Validators.required)
      this.form.get('receivedSocialAssistanceProgram').updateValueAndValidity()
      this.form.controls.dateOfSocialAssistanceInPast?.enable();
      this.form.controls.memberID?.enable();
    }
  }

  onReceivedSocialAssistanceProgramChange(value: any) {
    const dateOfSocialAssistanceInPast = this.form.controls.dateOfSocialAssistanceInPast?.value;
    this.displayPopUpAndErrorMsgDateOfSocialAssistanceInPast(dateOfSocialAssistanceInPast, value);
    this.validateODSSelection(this.moneyForImmediateNeed, value);
  }

  onDateOfSocialAssistanceInPastChange(date: any) {
    const receivedSocialAssistanceProgram = this.form.controls.receivedSocialAssistanceProgram?.value;
    this.displayPopUpAndErrorMsgDateOfSocialAssistanceInPast(date, receivedSocialAssistanceProgram)
  }

  onMoneyForImmNeedChange(value: any) {
    this.validateODSSelection(value, this.receivedSocialAssistanceProgram);
  }

  onLivingOnFirstNationsReserveLandChange(value: any){
    if (value === 'no')  {
      this.isLivingOnFirstNationsLand = false;
      this.firstNationError = undefined;
      this.firstNationErrorParam = undefined;
    }
    if (value === 'yes' && this.hasDisability === true) {
      this.isLivingOnFirstNationsLand = true;
      this.firstNationError = undefined;
      this.firstNationErrorParam = undefined;
    }
    if (value === 'yes' && this.hasDisability === false) {
      this.isLivingOnFirstNationsLand = true;
      this.openDialog(StartTwoPopup.livingOnFirstNationsReserveLand);
      this.firstNationErrorParam = this.officeLocationLinkParam;
    }
    if(this.hasDisability && this.isLivingOnFirstNationsLand){
      this.form.get('firstNationsName')?.enable();
    }else{
      this.form.get('firstNationsName')?.disable();
      this.form.get('firstNationsName')?.reset();
    }
  }

  onNeedTrusteeChange(value: any) {
    if(value === 'trusteeIdentified'){
      this.trusteeIdentified = true;
    } else {
      this.trusteeIdentified = false;
      this.removeTrusteeAddressData(this.applicationAnswers.jsonData);
      this.removeAndDisableTrusteeFields();
    }
  }

  onRelationshipChange(value: any) {
    this.isRelatedToLocalOfficeAssistance = value === RelationshipType.LOCAL_OFFICE_ASSISTANCE;
    this.isRelationShipToApplicantSelected = !!value;

    if(this.isRelationShipToApplicantSelected){
      this.form.get('applyingForSomeoneElseRelationshipNeedTrustee')?.enable()
      this.form.get('applyingForSomeoneElseRelationshipNeedTrustee').addValidators(Validators.required)
      this.form.get('applyingForSomeoneElseRelationshipNeedTrustee').updateValueAndValidity()

      if(value === RelationshipType.RELATIONSHIP_ORGANIZATION_TRUSTEE && this.trusteeType !== TrusteeType.ORGANIZATION_TRUSTEE){
        if(!!this.trusteeType){
          this.form.get('applyingForSomeoneElseRelationshipNeedTrustee')?.reset()
          this.removeTrusteeAddressData(this.applicationAnswers.jsonData)
        }
        this.trusteeType= TrusteeType.ORGANIZATION_TRUSTEE;
      }else if(value !== RelationshipType.RELATIONSHIP_ORGANIZATION_TRUSTEE && this.trusteeType !== TrusteeType.INDIVIDUAL_TRUSTEE){
        if(!!this.trusteeType){
          this.form.get('applyingForSomeoneElseRelationshipNeedTrustee')?.reset()
          this.removeTrusteeAddressData(this.applicationAnswers.jsonData)
        }
        this.trusteeType= TrusteeType.INDIVIDUAL_TRUSTEE;
      }
      this.needTrustee = this.trusteeType === TrusteeType.ORGANIZATION_TRUSTEE ? NeedOrganizationTrustee : NeedTrustee;
    }else{
      this.form.get('applyingForSomeoneElseRelationshipNeedTrustee')?.reset()
      this.form.get('applyingForSomeoneElseRelationshipNeedTrustee')?.disable()
      delete this.applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee;

      this.removeTrusteeAddressData(this.applicationAnswers.jsonData)
      this.removeAndDisableTrusteeFields();
    }
  }

  onPersonWithDisabilityChange(value: any){
    if (value === 'yes') {
      this.hasDisability = true;
      this.firstNationError = undefined;
      this.firstNationErrorParam = undefined;
    }
    if (value === 'no' && this.isLivingOnFirstNationsLand === false) {
      this.hasDisability = false;
      this.firstNationError = undefined;
      this.firstNationErrorParam = undefined;
    }
    if (value === 'no' && this.isLivingOnFirstNationsLand === true) {
      this.hasDisability = false;
      this.form.get('livingOnFirstNationsReserveLand').setErrors({customErrorKey: 'start-two.livingOnFirstNationsReserveLand.error'});
      this.openDialog(StartTwoPopup.livingOnFirstNationsReserveLand);
      this.firstNationErrorParam = this.officeLocationLinkParam;
    }
    if(this.hasDisability && this.isLivingOnFirstNationsLand){
      this.form.get('firstNationsName')?.enable();
    }else{
      this.form.get('firstNationsName')?.disable();
      this.form.get('firstNationsName')?.reset();
    }
  }

  onFirstNationsNameChange(value: any) {
    if(!!value && firstNationsODSPRedirectsNames.includes(value)){
      const popup = StartTwoPopup.redirectODSPFirstNations;
      this.openDialog(popup)
    }
  }

  onReceivingMoneyFromExistingProgramChange(value: any){
    if (value === 'yes') {
      const popup = StartTwoPopup.notEligible;
      popup.bodyLink = this.notEligibleBodyLinkParam;
      this.openDialog(popup);
      this.receiveMoneyError = 'start-two.dialog.notEligible.body';
    } else {
      this.receiveMoneyError = undefined;
    }
  }

  onReleasedFromInstitutionChange(value: any){
    if (value === 'yes') {
      if (!this.form.controls.dateOfReleaseFromInstitution) {
        this.form.addControl('dateOfReleaseFromInstitution', this.fb.control(''));
      }
      this.isReleasedFromInstitution = true;
    } else {
      this.isReleasedFromInstitution = false;
      this.previousDateOfRelease = undefined;
      this.form?.removeControl('dateOfReleaseFromInstitution');
      delete this.applicationAnswers.jsonData.dateOfReleaseFromInstitution;
    }
  }

  onDateOfReleaseFromInstitutionChange(value: any) {
    if (!!value && SadaCustomValidator.isValidDate([value]) &&
        !SadaCustomValidator.isInPastExcludeToday([value]) &&
        !SadaCustomValidator.isDateWithinDaysIncludeToday([value, '10']) &&
        this.previousDateOfRelease !== value) {
      this.openDialog(StartTwoPopup.dateOfRelease);
    }
    this.previousDateOfRelease = value;
  }

  removeDynamicQuestions() {
    if (!this.isReleasedFromInstitution && this.form?.get('dateOfReleaseFromInstitution')) {
      this.form?.removeControl('dateOfReleaseFromInstitution');
      delete this.applicationAnswers.jsonData.dateOfReleaseFromInstitution;
    }

    if (!this.hasDisability || !this.isLivingOnFirstNationsLand) {
      this.form?.removeControl('firstNationsName');
      delete this.applicationAnswers.jsonData.firstNationsName;
      delete this.applicationAnswers.jsonData.firstNationsNameCode;
    }

    if (!this.isApplyingForSomeoneElse) {
      delete this.applicationAnswers.jsonData.applyingForSomeoneElseRelationship;
      delete this.applicationAnswers.jsonData.applyingForSomeoneElseRelationshipNeedTrustee;
      delete this.applicationAnswers.jsonData.isAuthorizedIBAUUser;
      this.form.removeControl('applyingForSomeoneElseRelationship');
      this.form.removeControl('applyingForSomeoneElseRelationshipNeedTrustee');
      this.removeTrusteeAddressData(this.applicationAnswers.jsonData);
      this.removeAndDisableTrusteeFields();
    }

    if (!this.trusteeIdentified) {
      this.removeTrusteeAddressData(this.applicationAnswers.jsonData);
      this.removeAndDisableTrusteeFields();
    }

    if (!this.hasReceivedSocialAssistanceInPast && this.form?.get('dateOfSocialAssistanceInPast')) {
      delete this.applicationAnswers.jsonData.receivedSocialAssistanceProgram;
      delete this.applicationAnswers.jsonData.dateOfSocialAssistanceInPast;
      delete this.applicationAnswers.jsonData.memberID;
      this.form.removeControl('receivedSocialAssistanceProgram');
      this.form.removeControl('dateOfSocialAssistanceInPast');
      this.form.removeControl('memberID');
    }

    if (!StatusInCanadaComponentUtil.isStatusRequireImmigrationRelatedFields(this.form.get('statusInCanada').value)) {
      delete this.applicationAnswers.jsonData.arrivalDateToCanada;
      if (this.form?.get('arrivalDateToCanada')) this.form.removeControl('arrivalDateToCanada')
    }
  }

  displayPopUpAndErrorMsgDateOfSocialAssistanceInPast(dateOfSocialAssistanceInPast, receivedSocialAssistanceProgram){
    if (dateOfSocialAssistanceInPast && this.form.controls.receivedSocialAssistanceInPast.value !== 'no') {
      if (SadaCustomValidator.isValidDate([dateOfSocialAssistanceInPast], 'YYYY/MM')) {
        const trimmedDate = dateOfSocialAssistanceInPast.replace(/\s/g, '');
        const momentDate = moment(trimmedDate, 'YYYY/MM');

        if (SadaCustomValidator.isDateWithinPastMonthsOnCondition(momentDate, 0, 3, receivedSocialAssistanceProgram === ProgramType.ONW)) {
          this.form.get('dateOfSocialAssistanceInPast')
            .setErrors({customErrorKey: 'error.reopenPastApplication', customErrorParam: this.dateOfSocialAssistanceInPastParam});
          const popup = StartTwoPopup.reOpenApplication
          popup.bodyLink = this.dateOfSocialAssistanceInPastParam;
          this.openDialog(StartTwoPopup.reOpenApplication);
        } else{
          this.form.get('dateOfSocialAssistanceInPast').setErrors(null);
        }
      }
    }
  }

  validateODSSelection(moneyForImmediateNeed, receivedSocialAssistanceProgram) {
    if (moneyForImmediateNeed === 'no' && receivedSocialAssistanceProgram === ProgramType.ODS) {
      const popup = StartTwoPopup.reOpenODSApplication
      popup.bodyLink = this.officeLocationLinkParam;
      this.openDialog(StartTwoPopup.reOpenODSApplication);
      this.form.get('receivedSocialAssistanceProgram')
        .setErrors({customErrorKey: 'error.reopenPastODSApplication', customErrorParam: this.officeLocationLinkParam});
    } else {
      this.form.get('receivedSocialAssistanceProgram').setErrors(null);
    }
  }

  private filterApplicantRelationshipForPublicFlow() {
    if(!this.isAuthorizedUser)
    {
      // tslint:disable-next-line:max-line-length
      this.relationshipsToApplicantChoices=this.relationshipsToApplicantChoices.filter(choice => choice.value !== RelationshipType.APPLICATION_SUPPORT);
    }
  }
}
