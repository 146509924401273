<div class="form-group" [formGroup]="form">
  <sd-text-question *ngIf="trusteeType===trusteeTypeOrganizationTrustee"
                    [showError]="showError" [id]="'start-two.trusteeInfo.' + trusteeType + '.organizationName'"
                    [required]="true"
                    [capitalizeFirstLetter]= true
                    [validationRegex]="nameCharacterPattern" [validationRegexError]="'error.invalid.nameCharacter'"
                    [minLength]= 2 [maxLength]= 130
                    [characterWidth]= 20 [truncateMultipleSpaces]="true"
                    [controlName]="formControlNames.organizationName.name">
  </sd-text-question>
  <sd-text-question [showError]="showError" [id]="'start-two.trusteeInfo.' + trusteeType + '.trusteeFirstName'"
                    [required]="true"
                    [validationFns]="firstNameValidationFns" [capitalizeFirstLetter]= true
                    [minLength]= 1 [maxLength]= 65
                    [characterWidth]= 20 [truncateMultipleSpaces]="true"
                    [controlName]="formControlNames.trusteeFirstName.name">
  </sd-text-question>
  <sd-text-question [showError]="showError" [id]="'start-two.trusteeInfo.' + trusteeType + '.trusteeLastName'"
                    [required]="!isNoLastNameSelected()"
                    [validationFns]="lastNameValidationFns" [capitalizeFirstLetter]= true
                    [minLength]= 1 [maxLength]= 65
                    [characterWidth]= 20 [truncateMultipleSpaces]="true"
                    [controlName]="formControlNames.trusteeLastName.name">
  </sd-text-question>

  <sd-checkbox-question [id]="'start-two.trusteeInfo.' + trusteeType + '.trusteeNoLastName'" [options]="noLastNameCheckBoxItems"
                        [showLabel]=false
                        (toggle)="onNoLastNameCheckBoxChanges($event)">
  </sd-checkbox-question>

  <sd-text-question [showError]="showError" [id]="'start-two.trusteeInfo.' + trusteeType + '.phone.label'"
                    [dataType]="dataType.PN"
                    [required]= true
                    [characterWidth]= 10
                    [hintText]="'start-two.trusteeInfo.' + trusteeType + '.phone.hintText'"
                    [hintExpandableContent]="'start-two.trusteeInfo.' + trusteeType + '.phone.hintTextContent'"
                    [controlName]="formControlNames.trusteePhone.name">
  </sd-text-question>
  <sd-text-question [showError]="showError" [id]="'start-two.trusteeInfo.' + trusteeType + '.email'"
                    [showCustomRequiredErrorMessage]="customRequiredErrorMessage"
                    [validationFns]="emailValidationFns"
                    [asyncValidationFns]="emailAsyncValidationFns"
                    [required]="trusteeType === 'organizationTrustee' ? false : true"
                    (keydown.enter)="preventDefaultAction($event)"
                    [characterWidth]=20
                    [hintText]="'emailHint'"
                    [controlName]="formControlNames.trusteeEmail.name">
  </sd-text-question>
  <sd-radio-question [choices]="languages" [showError]="showError" [id]="'start-two.trusteeInfo.' + trusteeType + '.contactLanguage'"
                     [required]= true
                     [controlName]="formControlNames.trusteeContactLanguage.name">
  </sd-radio-question>
  <sd-text-question *ngIf="trusteeType===trusteeTypeOrganizationTrustee"
                    [showError]="showError" [id]="'start-two.trusteeInfo.' + trusteeType + '.trusteeIdentificationNumber.label'"
                    [characterWidth]=20
                    [minLength]="1" [maxLength]="10"
                    [validationFns]="trusteeIdentificationValidationFns"
                    [customMinMaxLenErrMsg]="'start-two.trusteeInfo.organizationTrustee.trusteeIdentificationNumber.error.invalid.minMaxLength'"
                    [hintText]="'start-two.trusteeInfo.organizationTrustee.trusteeIdentificationNumber.hintText'"
                    [controlName]="formControlNames.trusteeIdentificationNumber.name">
  </sd-text-question>

  <div class="ontario-alert ontario-alert--informational trustee-info">
    <ng-container *ngIf="trusteeType===trusteeTypeOrganizationTrustee; else trusteeTypeIndividual">
      <p id="trustee-org-info-content" [innerHTML]="'start-two.trustee.org.banner.text2' | translate"></p>
    </ng-container>
    <ng-template #trusteeTypeIndividual>
      <p id="trustee-info-content" [innerHTML]="'start-two.trustee.banner.text2' | translate"></p>
    </ng-template>
  </div>
</div>
