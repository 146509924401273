import {TrusteeFormControlConfigs} from './trustee-info-component.formcontrols';

export class TrusteeInfoComponentUtil {
  static addTrusteeInfoFormControls(form: any, formBuilder: any, trusteeType: string) {
    for (const v of Object.values(TrusteeFormControlConfigs[trusteeType]) as any) {
      form.addControl(v.name, formBuilder.control(''));
    }
  }

  static removeTrusteeInfoFormControls(form: any, formBuilder: any, applicationAnswers: any, trusteeType: string) {
    for (const v of Object.values(TrusteeFormControlConfigs[trusteeType]) as any) {
      form.removeControl(v.name);
      delete applicationAnswers.jsonData[v.name];
    }
  }
}
