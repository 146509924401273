import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ActivatedRoute, Router} from '@angular/router'
import {Idle} from '@ng-idle/core';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {Subscription} from 'rxjs';

import {IntakeService} from '../../services/intake.service'
import {AppStateService} from '../../services/app-state.service'
import {EidService} from '../../services/eid.service'
import {ButtonUtil} from '../../utils/button-util'
import {ApplicationResponse} from '../../models/application-response.model'
import {SadaCustomValidator} from '../../validator/sada-custom-validator'
import {ESignatureService} from '../../services/esignature.service';
import {ApplicationAnswers} from '../../models/data.model';
import {CaseWorkerAuthorizeService} from '../../services/caseWorkerAuthorizeService';
import {PageBaseComponent} from '../PageBaseComponent';
import {ExternalRouter} from '../../external.router';
import {ConfigService} from '../../services/config.service';
import {SadaErrorCodes} from '../../utils/sada-error-codes';
import {WindowService} from '../../services/window.service';
import {CheckboxItem} from '../../common/ui/multi-option-checkbox-question/multi-option-checkbox-question.component';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {PageInfo} from '../../models/page-map';
import {RelationshipType} from '../../models/relationship-type';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent extends PageBaseComponent implements OnInit, OnDestroy {
  private data: ApplicationAnswers = {jsonData: {}};

  subscriptions$: Subscription[] = [];
  failedSubmit: boolean;
  applicantConsents: CheckboxItem[];
  dataSharingItems: CheckboxItem[];

  constructor(private fb: FormBuilder, private router: Router,
              public route: ActivatedRoute,
              public intakeService: IntakeService,
              public appStateService: AppStateService,
              private eidService: EidService,
              private esignatureService: ESignatureService,
              public dialog: MatDialog,
              private authorizeService: CaseWorkerAuthorizeService,
              public translator: TranslateService,
              public ngZone: NgZone,
              public idle: Idle,
              public externalRouter: ExternalRouter,
              protected configService: ConfigService,
              private windowService: WindowService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intakeService, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService,
      loadingSpinnerService);
    this.pageId = PageInfo.consent;
    this.router = router;
  }

  ngOnInit() {
    this.form = this.fb.group({
      dataSharingForMLITSD: [],
    });
    this.form.controls?.dataSharingForMLITSD?.setValue('yes');

    this.route.data.subscribe((data: {appData: ApplicationAnswers}) => {
      this.data = data.appData;
      this.applicantConsents = this.getCheckBoxOptions(data?.appData?.jsonData);
      this.populateDataSharingItems(this.data);
      // Initialize form with application data
      this.initializeForm();
    })
  }

  ngOnDestroy() {
    super.onDestroy()
  }

  onSubmit() {
    ButtonUtil.toggleContinueButton(false, '#continue-button');
    this.applicationAnswers.jsonData.dataSharingForMLITSD = this.form.controls?.dataSharingForMLITSD?.value ?
      this.form.controls.dataSharingForMLITSD.value : 'no';
    // tslint:disable-next-line:max-line-length
    this.intakeService.submitQuestionnaire(this.applicationAnswers.jsonData.dataSharingForMLITSD).subscribe((response: ApplicationResponse) => {
        this.onQuestionnaireSubmitted(response)
      }, (err) => {
        if (err.status === 400 && (SadaErrorCodes.EC0005 === err.error.errorCode || SadaErrorCodes.AUTHEC04 === err.error.errorCode)) {
          this.failedSubmit = true;
          this.router.navigate(['/', PageInfo.globalError], { queryParams: { errorCode: err.error.errorCode }});
        } else {
          this.windowService.scrollToTop()
          ButtonUtil.toggleContinueButton(true, '#continue-button');
          this.appStateService.emitState({hasHttpError: true});
        }
    });
  }

  onDataSharingCheckboxChange(): void {
    if(this.form.controls?.dataSharingForMLITSD?.value === 'yes'){
      this.form.controls?.dataSharingForMLITSD?.setValue('no')
    } else {
      this.form.controls?.dataSharingForMLITSD?.setValue('yes')
    }
  }

  haveNotAcceptedTerms() {
    return this.applicantConsents?.find((applicant => {
      return !applicant.checked
    })) != null;
  }

  private populateDataSharingItems(jsonData: any): void {
    const isDataSharingforMLITSDChecked = jsonData?.jsonData?.dataSharingForMLITSD !== 'no'
    this.dataSharingItems = [{
      label: 'consent.applicantType.applicant',
      labelParam: {firstName: jsonData?.jsonData?.firstName},
      value: isDataSharingforMLITSDChecked ? 'yes' : 'no',
      checked: isDataSharingforMLITSDChecked}];

    this.form.controls?.dataSharingForMLITSD?.setValue(isDataSharingforMLITSDChecked ? 'yes' : 'no')
  }

  private onQuestionnaireSubmitted(response: ApplicationResponse) {

    this.eidService.isApplicationEligible().subscribe(persons =>{
      this.eidService.setEligiblePersons(persons)
      if (persons.length >= 1) {
        if (this.isSingleAppliant()) {
          this.router.navigate(['/', 'eid', 'landing'])
        } else {
          this.router.navigate(['/', 'eid', 'confirm'])
        }
      } else {
        this.onESignatureEligibleResult(response)
      }
    }, () => {
      this.onESignatureEligibleResult(response)
    })

  }

  private onESignatureEligibleResult(applicationResponse: ApplicationResponse) {
    this.esignatureService.isApplicationESignEligible().subscribe((result) => {
      if (result) {
        this.router.navigate(['/', 'esignature'])
      } else {
        this.intakeService.submitApplication().subscribe(() => {
          this.router.navigate(['/', PageInfo.confirmation],
            {
              queryParams: {
                esignredirect: false
              }
            })
        }, () => {
          ButtonUtil.toggleContinueButton(true, '#continue-button')
        });
      }
    });
  }

  private getCheckBoxOptions(jsonData: any): CheckboxItem[] {
    const checkboxItems: CheckboxItem[] = []

    if (jsonData) {
      checkboxItems.push({
        label: 'consent.applicantType.applicant',
        labelParam: {firstName: jsonData.firstName},
        value: 'applicant',
        checked: false
      })

      if (jsonData.spouseFirstName && this.spouseNotUnderage(jsonData.spouseDateOfBirth)) {
        checkboxItems.push({
          label: 'consent.applicantType.spouse',
          labelParam: {firstName: jsonData.spouseFirstName},
          value: 'spouse',
          checked: false
        })
      }

      jsonData.childList?.forEach((child, childIndex) => {
        if (SadaCustomValidator.validateOverAge(child.childDateOfBirth, 18)) {
          checkboxItems.push({
            label: 'consent.applicantType.adultChild',
            labelParam: {firstName: child.childFirstName},
            value: 'child' + childIndex,
            checked: false
          })
        }
      })
    }
    return checkboxItems
  }

  toggleCheckbox(event: any[]) {
    this.applicantConsents.forEach(option => {
      option.checked = event.includes(option.value);
    });
  }

  spouseNotUnderage(spouseDOB): boolean {
    spouseDOB = spouseDOB.replace(/\s/g, '');
    const age = moment().diff(
      moment(spouseDOB, 'YYYY/MM/DD'),
      'years',
      true
    );
    return age >= 18;
  }

  isSingleAppliant(): boolean {
    if (this.applicantConsents.length > 1) {
      return false
    } else {
      return true
    }
  }

  get titleForApplicant(): string {
    const applyingForSomeoneElse = this.data?.jsonData?.applyingForYourselfOrSomeoneElse === 'APPLICATION_OTHER';
    const localOfficeAssistance = this.data?.jsonData?.applyingForSomeoneElseRelationship === RelationshipType.LOCAL_OFFICE_ASSISTANCE;
    const primaryApplicantOver18 = SadaCustomValidator.validateOverAge(this.data?.jsonData?.dateOfBirth, 18);

    if (this.authorizeService.isAuthorized() || (applyingForSomeoneElse && localOfficeAssistance)) {
      return 'btn.continue';
    } else {
      return (applyingForSomeoneElse || !primaryApplicantOver18) ? 'consent.pleaseSubmitNow' : 'btn.continue';
    }
  }

  postInitializeForm() {}

  preSaveApplication() {}
}
