import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {PageBaseComponent} from '../PageBaseComponent';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {IntakeService} from '../../services/intake.service';
import {TranslateService} from '@ngx-translate/core';
import {Idle} from '@ng-idle/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ExternalRouter} from '../../external.router';
import {SadaCustomValidator} from '../../validator/sada-custom-validator';
import {BankingService} from '../../services/banking.service';
import {Choice} from '../../common/utils/questions/question-choices';
import {distinctUntilChanged, filter, startWith, take, takeUntil} from 'rxjs/operators';
import {amountMaxLength, positiveCurrencyPattern} from '../../common/utils/validators/custom-validators';
import {ConfigService} from '../../services/config.service';
import {Observable, of} from 'rxjs';
import {PageInfo} from '../../models/page-map';
import {AuthService} from '../../services/auth.service';
import {PageService} from '../../services/page.service';
import {LoadingSpinnerService} from '../../services/loading-spinner.service';
import {bankNameChoices} from './bank-details.util';
import {FieldsCheckUtil} from '../../utils/fields-check-util';

@Component({
  selector: 'sd-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss']
})
export class BankDetailsComponent extends PageBaseComponent implements OnInit, OnDestroy {
  form: FormGroup;
  showError = false;
  bankNameValidationFns: { validationFunction: (params: any) => boolean; errorKey: string; }[];
  accountValueValidationFns: { validationFunction: (params: any) => boolean; errorKey: string; }[];
  branchNumberValidationFns: { validationFunction: (params: any) => boolean; errorKey: string; }[];
  institutionNumberValidationFns: { validationFunction: (params: any) => boolean; errorKey: string; }[];
  branchNumberAsyncValidationFns: [{ validationFunction: any; errorKey: string; }];
  institutionNumberAsyncValidationFns: [{ validationFunction: any; errorKey: string; }];
  accountNumberValidationFns: [{ validationFunction: any; errorKey: string;}, { validationFunction: any; errorKey: string;}];
  invalidBankDetailError: string;
  accountTypes: Choice[] = [
    {value: 'chequing', label: 'bank-details.account.choices.chequing'},
    {value: 'savings', label: 'bank-details.account.choices.savings'},
  ];
  accountValuePattern = positiveCurrencyPattern;
  accountValueMaxLength = amountMaxLength;
  accountNumberParam;

  constructor(public formBuilder: FormBuilder, private router: Router, public route: ActivatedRoute,
              public intake: IntakeService, public translator: TranslateService,
              public ngZone: NgZone, public idle: Idle, public dialog: MatDialog,
              public externalRouter: ExternalRouter, private bankingService: BankingService,
              protected configService: ConfigService,
              protected authService: AuthService,
              protected pageService: PageService,
              protected loadingSpinnerService: LoadingSpinnerService) {
    super(intake, translator, ngZone, idle, dialog, route, externalRouter, configService, authService, pageService, loadingSpinnerService);
    this.pageId = PageInfo.bankDetails;
  }

  ngOnInit(): void {
    this.setUpForm();
    this.initializeForm();
    this.form.controls.provideBankDetails?.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      Object.keys(this.form.controls).forEach(key => {
        if (key !== 'provideBankDetails') {
          if (value === 'no') {
            this.form.controls[key].disable();
          } else {
            this.form.controls[key].enable();
          }
        }
      })
    });

    this.form.controls.bankName?.valueChanges.pipe(takeUntil(this.valueChangeSubjects$)).subscribe(value => {
      const bankInfo = bankNameChoices().filter(bank=>bank.value === value);
      this.accountNumberParam = {maxAccountNumberLength: bankInfo.length !== 0 ? bankInfo[0].relatedValue : null}
      this.accountNumberValidationFns = [
        {
          validationFunction: () => {
            const accountNumber = this.form.get('accountNumber')?.value;
            return SadaCustomValidator.validateAccountNumber(accountNumber, value)
          },
          errorKey: bankInfo.length !== 0 ? 'bank-details.error.invalidAccountNumber.custom' : 'bank-details.error.invalidAccountNumber'
        },
        {
          validationFunction: (v: []) => {
            const accountNumber = this.form.get('accountNumber')?.value;
            return SadaCustomValidator.validateMinAccountNumberLength(accountNumber)
          }, errorKey: 'bank-details.error.invalidAccountNumber'
        }
      ];
    });
  }

  ngOnDestroy() {
    super.onDestroy();
  }

  setUpForm() {
    this.form = this.formBuilder.group({
      provideBankDetails: [],
      bankName: [],
      accountValue: [],
      accountType: [],
      branchNumber: [],
      institutionNumber: [],
      accountNumber: []
    });

    this.bankNameValidationFns = [{
      validationFunction: SadaCustomValidator.validateBankName,
      errorKey: 'bank-details.error.invalidBankName'
    }];
    this.accountValueValidationFns = [
      {
        validationFunction: (value: []) => {
          return SadaCustomValidator.validateMaxNumericValueLength([...value, amountMaxLength]);
        },
        errorKey: 'error.invalid.max.amount'
      },
    ];
    this.branchNumberValidationFns = [{
      validationFunction: SadaCustomValidator.validateBranchNumber,
      errorKey: 'bank-details.error.invalidBranchNumber'
    }];
    this.institutionNumberValidationFns = [{
      validationFunction: SadaCustomValidator.validateInstitutionNumber,
      errorKey: 'bank-details.error.invalidInstitutionNumber'
    }];
    this.branchNumberAsyncValidationFns = [{
      validationFunction: () => this.validateInstitutionBranchNumber('branchNumber', this.form.get('branchNumber'), this.form.get('institutionNumber')),
      errorKey: 'bank-details.error.invalidDetails'
    }];
    this.institutionNumberAsyncValidationFns = [{
      validationFunction: () => this.validateInstitutionBranchNumber('institutionNumber', this.form.get('branchNumber'), this.form.get('institutionNumber')),
      errorKey: 'bank-details.error.invalidDetails'
    }];
  }

  onSubmit(toContinue: boolean) {
    this.showPsRedirectError = false;  // Reset the error flag.
    this.showRequiredInfoBanner = false;
    let notSubmitForm = false;

    if (this.form.valid) {
      if (this.provideBankDetails === 'yes' ) {
        this.bankingService.validateBankDetails(this.institutionNumber, this.transitNumber, (result) => {
          if (!result) {
            this.invalidBankDetailError = 'bank-details.error.invalidDetails';
            this.scrollToInvalidFormControl(toContinue)
          } else {
            if(this.form.valid) {
              this.invalidBankDetailError = '';
              this.saveAndContinue(toContinue);
            }else{
              this.showError = true
              this.scrollToInvalidFormControl(toContinue)
            }
          }
        })
      } else {
        this.saveAndContinue(toContinue);
      }
    } else if (this.form.invalid || FieldsCheckUtil.findInvalidField(this.form.controls)) {
      this.showError = true
      this.scrollToInvalidFormControl(toContinue);
    } else {
      // Below handling is required to handle ASYNC validators. Submission should be delayed until all async validators are executed.
      // Until async validators are done, status would be 'PENDING'
      this.subscriptions$.push(
        this.form.statusChanges.pipe(
          distinctUntilChanged(),
          startWith(this.form.status),
          filter(status => status !== 'PENDING'),
          take(1)).subscribe(status => {
          this.form.updateValueAndValidity();

          // Until async validators are done status would be 'PENDING'
          if (!notSubmitForm && this.form.valid && status === 'VALID') {
            this.saveAndContinue(toContinue);
          } else if(!notSubmitForm && status !== 'PENDING') {
            notSubmitForm = true;
            this.showError = true
            this.scrollToInvalidFormControl(toContinue);
          }
        }));
    }
  }

  private saveAndContinue(toContinue: boolean) {
    this.saveForm(toContinue)?.subscribe(x => {
      if (toContinue) {
        this.router.navigate(['/', 'intake', PageInfo.review]);
      } else {
        this.handleSaveAndExit(this.pageId, this.applicationAnswers.jsonData.email);
      }
    });
  }

  get provideBankDetails() {
    return this.form.controls.provideBankDetails?.value;
  }
  get branchNumber() {
    return this.form.controls.branchNumber?.value;
  }
  get institutionNumber() {
    return this.form.controls.institutionNumber?.value;
  }
  get transitNumber() {
    return this.form.controls.branchNumber?.value;
  }

  postInitializeForm() {
  }

  preSaveApplication() {
    Object.keys(this.form.controls).forEach(key => {
      if (this.form.get(key).status === 'DISABLED') {
        delete this.applicationAnswers.jsonData[key];
      }
    })
  }

  get choices() {
    return bankNameChoices();
  }

  private validateInstitutionBranchNumber(controlName: string, branchNumberControl: AbstractControl,
                                          institutionNumberControl: AbstractControl): Observable<boolean> {
    if (institutionNumberControl.value && branchNumberControl.value) {
      if (controlName === 'institutionNumber' && !this.bankingService.isManualTriggerValidation()) {
        this.bankingService.setManualTriggerValidation(true);
        branchNumberControl.updateValueAndValidity(); // also trigger validation of branch when institution is updated
      } else if (controlName === 'branchNumber' && !this.bankingService.isManualTriggerValidation()) {
        this.bankingService.setManualTriggerValidation(true);
        institutionNumberControl.updateValueAndValidity(); // also trigger validation of institution when branch is updated
      } else {
        this.bankingService.setManualTriggerValidation(false);
      }
      return this.bankingService.validateInstitutionBranchNumber(institutionNumberControl.value, branchNumberControl.value);
    } else {
      return of(true);
    }
  }

  onBranchNumberChange(value: any) {
    return this.form.controls.institutionNumber.updateValueAndValidity();
  }
}
